.brand-intro-outer {
  position: relative;
  
  background-color: $color-black;
  overflow: hidden;

    &:before {
      content: "";

      position: absolute;
      top: 0;
      left: 10%;
      z-index: 1;

      width: 100%;
      height: 100%;

      background: linear-gradient(90deg, rgba(153,153,153,1) 0%, rgba(153,153,153,0.33) 10%, rgba(153,153,153,0) 20%);
      transform: skew(-35deg);
      opacity: 0.15;
    }

    &:after {
      content: "";
  
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
  
      width: 100%;
      height: 50%;
  
      background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    }

    .inner {
      position: relative;
      z-index: 3;
    }

}

.brand-intro {
  
  @include mq(screen-md) {
    display: flex;
    gap: $padding-lg;
  }

}

.brand-intro__headline {
  margin: 0 0 $padding-md;

    @include mq(screen-md) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      margin: 0;
    }

    .title {
      margin: 0 0 $padding-md;

      text-align: center;

        @include mq(screen-sm) {
          text-align: left;
        }

    }
    
    .logo-grid {
      
      @include mq(screen-sm) {
        justify-content: flex-start;
      }

    }

}

.no-flexbox-gap {
  
  .brand-intro__headline {
    
    @include mq(screen-md) {
      margin: 0 0 $padding-lg;
    }

  }

}

.brand-intro__title {
  margin: 0 auto $padding-md;

  font-family: $bw-heavy;
  font-size: $title-size-sm;
  text-transform: uppercase;
  line-height: 1;
  color: white;
  text-align: center;

    @include mq(screen-xs) {
      width: 9ch;
    }

    @include mq(screen-sm) {
      margin: 0 0 $padding-lg;

      font-size: $title-size-xl;
      text-align: left;
    }

    @include mq(screen-lg) {
      margin: 0 0 $padding-xl;

      letter-spacing: $kerning-md;

    }

}

.brand-intro__text {
  text-align: center;

  @include mq(screen-sm) {
    width: 75%;
    margin: 0 0 0 25%;

    text-align: left;
  }

  @include mq(screen-md) {
    width: calc(40% - 6rem);
    margin: 16rem 0 0;
  }
  
  .std-content {
    color: white;
  
    // p,
    // strong {
    //   color: white;
    // }

  }

}