.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  height: 100%;
  padding: $padding-md;

  background-color: rgba(white, 0.05);

    @include mq(screen-xs) {
      height: 100%;
    }


    @include mq(screen-lg) {
      padding: $padding-lg;
    }

}

.stat-item__icon {
  margin: 0 0 $padding-md;

  img,
  svg {
    display: block;
    width: 5rem;
    height: 5rem;

      @include mq(screen-lg) {
        width: 10rem;
        height: 10rem;
      }

  }

}

.stat-item__text {
  text-align: center;
}