.cs-hero {
  position: relative;

  background-color: $color-black;

    @media screen and (orientation: portrait) {
      height: 75vh;
    }

    @media screen and (orientation: landscape) {
      height: 100vh;
    }
}

.cs-hero__img-outer {
  position: relative;
  z-index: 1;

  height: 100%;

    &:after {
      content: "";

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 3;

      width: 100%;
      height: 50%;

      background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    }

}

.cs-hero__img {
  position: relative;
  z-index: 2;

  opacity: $img-fade;
  
  @include object-fit;
}

.cs-hero__content-outer {
  position: absolute;
  bottom: $padding-md;
  z-index: 2;
  
  width: 100%;

    @include mq(screen-lg) {
      bottom: $padding-lg;
    }
}

.cs-hero__content {
  
  @include mq(screen-sm) {
    width: 75%;
  }

  @include mq(screen-lg) {
    width: 66.667%;
  }

  .supporting-text {
    display: none;

      @include mq(screen-sm) {
        display: block;
      }
      
  }

}

.cs-hero__content__logo {
  display: block;
  width: 7.5rem;
  height: auto;
  margin: 0 0 $padding-md;

    @include mq(screen-sm) {
      width: 10rem;
    }

    @include mq(screen-lg) {
      margin: 0 0 $padding-lg;
    }

}

.cs-hero__arrow {
  display: block;
  width: 4rem;
  height: 4rem;
  margin: $padding-md 0 0;

    @include mq(screen-sm) {
      position: absolute;
      bottom: 0;
      right: $padding-md;
      z-index: 1;

      margin: 0;
    }

    @include mq(screen-lg) {
      right: $padding-lg;
    }

    svg {
      width: 4rem;

      fill: white;
      transition: fill 0.15s;
    }

    &:hover {
      
      svg {
        fill: $color-green;
      }

    }

}

.cs-hero__img-caption {
  position: absolute;
  bottom: $padding-sm;
  right: $padding-sm;
  z-index: 5;
}