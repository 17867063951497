// ====================================
// IMAGES
// ====================================

@mixin flex-img {
	display: block;
	width: 100%;
	height: auto;
    
    @include ie-11-support {
      flex-shrink: 0;
    }
}

@mixin object-fit {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@mixin ie-full-support {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {@content;}
	@supports (-ms-accelerator:true) {@content;}
}

@mixin ie-11-support {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {@content;}
}

@mixin ie-edge-support {
	@supports (-ms-accelerator:true) {@content;}
}

@mixin grad-clip-text {
  background: $grad-green;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin vertical-padding-xl {
  padding: $padding-md 0;

    @include mq(screen-sm) {
      padding: $padding-lg 0;
    }

    @include mq(screen-lg) {
      padding: $padding-xl 0;
    }

}

// ====================================
// TYPE
// ====================================


@mixin placeholder {  
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}

@mixin text-highlight {
	
	&::selection {@content}

}