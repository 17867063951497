.pg-footer-outer {
  position: relative;
  padding: $padding-lg 0;

    @include mq(screen-sm) {
      padding: $padding-xl 0;
    }
    
}

// Hide footer on services page when large enough

body.services .pg-footer-outer {
  
  @include mq(screen-sm) {
    display: none;
  }

}

.pg-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $padding-md;

    @include mq(screen-lg) {
      gap: $padding-lg;
    }

}

.no-flexbox-gap {

  .pg-footer {
  
    > * + * {
      margin: $padding-md 0 0;
    }

  }

}

.pg-footer__logo {
  
  svg {
    width: 10rem;
    fill: $color-black;
  }

}

.pg-footer__nav__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: $padding-md;

  list-style-type: none;

    a {
      text-decoration: none;
      color: $color-black;

        &:hover {
          color: $color-green;
        }

    }

}

.no-flexbox-gap {
  
  .pg-footer__nav__list li {
    margin: 1rem;
  }


}

.pg-footer__email {
  font-family: $larsseit-bold;
  font-size: $font-size-md;
  color: $color-black;

    @include mq(screen-lg) {
      font-size: $font-size-lg;
    }

    &:hover {
      color: $color-green;
    }

}

.social {
  display: flex;
  flex-direction: column;
  align-items: center;

    @include mq(screen-xs) {
      flex-direction: row;
      justify-content: center;
    }

}

.social__title {
  margin: 0 0 $padding-sm;

    @include mq(screen-xs) {
      margin: 0 3rem 0 0;
    }

}

.social__list {
  display: flex;
  list-style-type: none;
}

.social__list__item + .social__list__item {
  margin: 0 0 0 3rem;
}

.social__list__link {
  display: block;
  width: 3rem;
  height: 3rem;
  padding: 0;

  background: $color-grey-light;

    svg {
      width: 3rem;
      height: 3rem;

      fill: black;
    }

    &:hover {
      background: $grad-green;

        svg {
          fill: white;
        }
        
    }

}