$animation-distance-up: 6rem;
$animation-distance-down: -3rem;
$animation-distance-left: 6rem;
$animation-distance-right: -6rem;
$transform-speed: 0.5;
$opacity-speed: 0.75;

@include mq(screen-sm) {

  .js-fade-up {
    transform: translateY($animation-distance-up);
    opacity: 0;
    transition: transform #{$transform-speed}s ease-in, opacity #{$opacity-speed}s ease-in;

      &.has-animated {
        transform: translateY(0);
        opacity: 1;
      }

  }

  .js-fade-down {
    transform: translateY($animation-distance-down);
    opacity: 0;
    transition: transform #{$transform-speed}s ease-in, opacity #{$opacity-speed}s ease-in;

      &.has-animated {
        transform: translateY(0);
        opacity: 1;
      }

  }

  .js-fade-left {
    transform: translateX($animation-distance-left);
    opacity: 0;
    transition: transform #{$transform-speed}s ease-in, opacity #{$opacity-speed}s ease-in;

      &.has-animated {
        transform: translateY(0);
        opacity: 1;
      }

  }

  .js-fade-right {
    transform: translateX($animation-distance-right);
    opacity: 0;
    transition: transform #{$transform-speed}s ease-in, opacity #{$opacity-speed}s ease-in;

      &.has-animated {
        transform: translateY(0);
        opacity: 1;
      }

  }

  .js-fade-in {
    opacity: 0;
    transition: opacity #{$opacity-speed}s ease-in;

      &.has-animated {
        opacity: 1;
      }

  }

  .js-fade-in-slow {
    opacity: 0;
    transition: opacity #{$opacity-speed*2}s ease-in;

      &.has-animated {
        opacity: 1;
      }

  }
  
}