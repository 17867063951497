.block-slider__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $padding-sm;

  text-align: center;

    @include mq(screen-xs) {
      flex-direction: row;
      justify-content: space-between;

      text-align: left;
    }

}

.no-flexbox-gap {
  
  .block-slider__header .title {
    margin: 0 0 $padding-sm;
  }

}

.block-slider__overflow {
  position: relative;
  overflow: hidden;

    &:after {

      @include mq(screen-xs) {
        content: "";

        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;

        width: 20rem;
        height: 100%;

        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
      }

      @include mq(screen-lg) {
        width: 50rem;
      }

    }

}

.disabled .block-slider__overflow:after {
  display: none;
}

.block-slider__slides {
  position: relative;
  z-index: 1;
}

.block-slider__slide {
  width: 100%;
  margin-right: $padding-sm;

    @include mq(screen-xs) {
      width: calc(50% - 2rem);
    }

    @include mq(screen-md) {
      width: calc(33.333% - 2rem);
    }

}

.block-slider__title {
  font-family: $larsseit-bold;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  color: white;

    @include mq(screen-sm) {
      font-size: $font-size-md;
    }
}

.block-slider__text {
  font-size: $font-size-xs;
  line-height: $line-height-md;
  color: white;
}

// Controls

.block-slider__controls {
  display: flex;
  gap: 1rem;
}

.no-flexbox-gap {
  
  .slider-control--previous {
    margin: 0 1rem 0 0;
  }

}
