.media-carousel-outer {
  position: relative;
  padding: $padding-md 0;

  background-color: $color-black;

    @include mq(screen-sm) {
      padding: $padding-md 0 $padding-lg;
    }

    @include mq(screen-lg) {
      padding: $padding-md 0 $padding-xl;
    }

    .title {
      position: relative;
      top: 1.5rem;
      z-index: 2;

      max-width: $screen-md;
      margin: 0 auto;

      text-shadow: 0 2px 5px rgba(black, 0.2);

        @include mq(screen-lg) {
          top: 5rem;
        }

    }

}

.media-carousel {
  position: relative;

    @include mq(screen-xl) {
      width: $screen-xl;
      margin: 0 auto;
    }

    &:before,
    &:after {
      content: "";

      position: absolute;
      top: 0;
      z-index: 1;

      width: 10rem;
      height: 100%;

        @include mq(screen-lg) {
          width: 20rem;
        }

    }

    &:before {
      left: 0;

      background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    }

    &:after {
      right: 0;

      background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    }

    .slider-control {
      position: absolute;
      top: 50%;
      z-index: 2;

      transform: translateY(-50%);
    }

    .slider-control--previous {
      left: $padding-sm;

        @include mq(screen-sm) {
          left: $padding-md;
        }

        @include mq(screen-lg) {
          left: $padding-lg;
        }

    }

    .slider-control--next {
      right: $padding-sm;

        @include mq(screen-sm) {
          right: $padding-md;
        }

        @include mq(screen-lg) {
          right: $padding-lg;
        }

    }

}

.media-carousel__slide {
  width: calc(100% - 5rem);
  margin: 0 0.5rem;

    @include mq(screen-sm) {
      width: calc(100% - 10rem);
    }

    @include mq(screen-lg) {
      width: $screen-md;
    }

    @include mq(screen-xl) {
      width: $screen-lg;
    }
}

.media-carousel__slide__media {
	position: relative;
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;

		iframe,
		object,
		embed,
    img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

    img {
      z-index: 1;
      object-fit: cover;
      object-position: center;
    }

}

.media-carousel__slide__img-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  width: 100%;
  padding: $padding-md $padding-sm $padding-sm;

  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
}

.media-carousel__text {
  margin: $padding-md 0 0;
  padding: 0 $padding-md;

  text-align: center;

    @include mq(screen-sm) {
      width: $screen-sm;
      margin: $padding-md auto 0;
    }

    @include mq(screen-lg) {
      padding: 0 $padding-lg;
      margin: $padding-lg auto 0;
    }

    .btn {
      margin: $padding-md auto 0;

        @include mq(screen-lg) {
          margin: $padding-lg auto 0;
        }
        
    }

}
