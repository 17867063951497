.news-hero {
  position: relative;

  background-color: $color-black;

    .inner {
      position: relative;
      z-index: 2;
    }
}

.news-hero__img-outer {
  position: absolute;
  z-index: 1;

  width: 100%;
  height: 100%;
}

.news-hero__img {
  position: absolute;
  z-index: 1;
  
  width: 100%;
  height: 100%;

  opacity: $img-fade;
  @include object-fit;
}

.news-hero__content {
  
  @include mq(screen-sm) {
    max-width: $screen-sm;
  }

}

.news-hero__link {
  text-decoration: none;

    svg {
      width: 4rem;
      height: 4rem;

      fill: white;
      transition: fill $animation-speed-sm;
    }

    &:hover {
      
      svg {
        fill: $color-green;
      }

    }

}

.news-hero__img__caption {
  position: absolute;
  bottom: $padding-sm;
  right: $padding-sm;
  z-index: 3;
}