.logo-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: $padding-sm;

  list-style-type: none;
  
    @include mq(screen-sm) {
      gap: $padding-lg;
    }

}

.logo-grid__item {
  width: 7.5rem;

    @include mq(screen-sm) {
      width: 10rem;
    }

    @include mq(screen-lg) {
      width: 12rem;
    }

}

.no-flexbox-gap {
  
  .logo-grid__item {
    margin: $padding-xs;

      @include mq(screen-sm) {
        margin: $padding-sm;
      }
      
  }

}

.logo-grid__item__img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: contain;
  object-position: center;
}