.newsletter-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  grid-gap: $padding-sm;

  list-style-type: none;
}

.newsletter-grid__item__img-outer {
  position: relative;
  padding-bottom: 56.75%;
  margin: 0 0 $padding-sm;
}

.newsletter-grid__item__img {
  position: absolute;
  z-index: 1;

  @include object-fit;
}