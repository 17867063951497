// =================================================
// GLOBAL
// =================================================

body.is-fixed {
  overflow: hidden;
}

body.admin-bar {

    .pg-header {
      top: 3rem;
      margin: 3rem 0 0;
    }
    
}

.main-content {
  position: relative;
  z-index: 1;
}

iframe {
  border: none;
}

