.date-stamp {
  display: inline-block;
  padding: 0.5rem;

  font-family: $bw-heavy;
  font-size: $font-size-xs;
  text-transform: uppercase;
}

.date-stamp--lg {
  
  @include mq(screen-sm) {
    font-size: $font-size-md;
  }

}

.date-stamp--white {
  background-color: white;

  color: $color-black;
}

.date-stamp--black {
  background-color: $color-black;

  color: white;
}