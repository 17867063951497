.cta-banner {
  padding: $padding-lg 0;

  text-align: center;

  background-image: url('img/bg-gradient.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: $color-green;
  
  animation: cta-gradient 10s infinite;


    @include mq(screen-lg) {
      padding: $padding-xl 0;
    }

}

@keyframes cta-gradient {

  50% {
    background-image: url('img/bg-gradient-alt.jpg');
  }

}