.analysts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  grid-gap: $padding-sm;

  margin: 0 auto;

  list-style-type: none;
}

.analysts-grid__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $padding-md;
  border: 2px solid $color-grey-light;
}

.analysts-grid__item__img-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  margin: 0 0 $padding-md;
}

.analysts-grid__item__img {
  display: inline-block;
  width: 10rem;
  object-fit: contain;
  object-position: center;
}