.author {
  display: flex;
  align-items: flex-start;
  gap: $padding-sm;
}

.author__img-outer {
  width: 5rem;
  height: 5rem;
  flex-shrink: 0;
}

.no-flexbox-gap {
  
  .author__img-outer {
    margin: 0 $padding-sm 0 0;
  }

}

.author__img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
}

.author__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}