.file-group + .file-group {
  border-top: 1px solid white;

  padding: $padding-lg 0 0;
  margin: $padding-lg 0 0;
}

.file-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: $padding-sm;

  list-style-type: none;
}

.file-grid__item {
  position: relative;
  width: 20rem;
}

.file-grid__item__link {
  display: block;
  text-align: center;

  color: inherit;

    &:hover {
      color: $color-green;
    }

}

.file-grid__item__img-outer {
  position: relative;
  z-index: 1;
  padding-bottom: 56.75%;
  margin: 0 0 1rem;

  background-color: white;
}

.file-grid__item__img {
  position: absolute;
  z-index: 1;

  @include object-fit;
}

.file-grid__item__label {
  font-size: $font-size-xs;
  color: inherit;
}

// Fallbacks

.no-flexbox-gap {

  .file-grid {
    margin: -$padding-sm;
  }

  .file-grid__item {
    padding: $padding-sm;
  }

}
