// .video-grid {
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
//   grid-gap: $padding-sm;

//   list-style-type: none;
// }

// .video-grid__toggle {
//   display: block;

//   border: none;
//   background: none;
// }

// .video-grid__toggle:hover .btn:after {
//   right: 0;
// }

// .video-grid__toggle__img {
//   @include flex-img;
// }

// .video-grid__modal {
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 0;

//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   height: 100%;

//   background-color: rgba($color-black, 0.75);

//   visibility: hidden;
//   opacity: 0;

//     &.is-open {
//       z-index: 10000;
//       visibility: visible;
//       opacity: 1;
//     }

//     .video-embed {
//       width: 75%;
//     }

// }

.video-grid {
  list-style-type: none;
}

.video-grid__item + .video-grid__item {
  margin: $padding-md 0 0;
}
