// Form fields

.hbspt-form,
.contact-form {

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  textarea,
  select {
    display: block;
    width: 100%;
    border: 2px solid $color-black;
    -webkit-border-radius: 0;

    font-size: $font-size-sm;

      &:hover {
        border-color: $color-grey;
      }

      &:focus {
        outline: none;
        border-color: $color-green;
      }

      &.error {
        border-color: $color-error;
      }

      @include placeholder {
        color: $color-grey;
      }

  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="password"] {
    width: 100% !important;
    height: 6rem;
    padding: 0 1rem;
  }

  textarea {
    width: 100% !important;
    height: 20rem;
    padding: 1rem;
  }

  select {
    width: 100% !important;
    height: 6rem;
    padding: 0 0.5rem;
  }

}

// Layout

// .form-row + .form-row,
// .hs-form-field + .hs-form-field {
//   margin: $padding-md 0 0;
// }

.hs-form > *+* {
  margin: $padding-md 0 0;
}

.hbspt-form {
  
  fieldset {
    max-width: 100% !important;
  }

  fieldset > * {
    margin: $padding-md 0 0;
  }

  fieldset:first-child > *:first-child {
    margin: 0;
  }

  .input {
    margin: 0 !important;
  }

  .form-columns-1 .hs-form-field,
  .form-columns-2 .hs-form-field {
    width: 100% !important;
  }

  .form-columns-2 > *+* {
    margin: $padding-md 0 0;
  }

}

.hs-dependent-field > *+* {
  margin: $padding-md 0 0;
}

// Field labels and text

.form-field-label,
.hs-form-field > label {
  display: block;
  margin: 0 0 1rem;

  font-family: $larsseit-bold;
  line-height: $line-height-md;
}

.hs-field-desc {
  margin: 0 0 1rem;
}

.hs-richtext {
  line-height: $line-height-md;
}

// Radio/checkbox lists

.hbspt-form .inputs-list {
  list-style-type: none;

    li + li {
      margin: 1rem 0 0;
    }

    li input {
      margin: 0 1rem 0 0;
    }

    li span {
      font-family: $larsseit-bold;
    }

}

// File upload

.hs_file_upload_field > .input {
  padding: $padding-sm;

  background-color: $color-grey-light;
}

// Legal consent

.legal-consent-container {

  > *+* {
    margin: $padding-md 0 0;
  }

  .hs-richtext p {
    font-size: $font-size-sm;
    line-height: $line-height-md;
  }

}

// Recaptcha 

.hs_recaptcha {
  margin: $padding-md 0 0 !important;
}

// Submit button

.hs_submit {
  margin: $padding-md 0 0;
}

.hs-button {
  display: block;
  padding: 1rem 2rem;

  background-color: $color-green;
  border: none;

  font-family: $bw-heavy;
  font-size: $font-size-lg;
  text-transform: uppercase;
  color: $color-black;

  cursor: pointer;
  -webkit-appearance: none;

    &:hover {
      background: $color-black;
      color: white;
    }

}

// Validation

.hs-error-msgs {
  margin: $padding-xs 0 0;
  padding: $padding-sm;

  background-color: lighten($color-error, 40%);

    li {
      font-family: $larsseit-bold;
      color: $color-black;
    }

    li label {
      color: $color-black !important;
    }

}

.hs_error_rollup .hs-error-msgs {
  background-color: $color-error;

    li label {
      color: white !important;
    }

}