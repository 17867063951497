.team-grid {
  list-style-type: none;

    @include mq(screen-xs) {
      display: flex;
      flex-wrap: wrap;
      margin: -$padding-xs;
    }

}

.team-grid__item {
  position: relative;
  
    @include mq(screen-xs) {
      width: 50%;
      padding: $padding-xs;
    }

    @include mq(screen-md) {
      width: 33.333%;
    }

}

.team-grid__item + .team-grid__item {
  margin: $padding-sm 0 0;

    @include mq(screen-xs) {
      margin: 0;
    }

}

.team-grid__item__link {
  position: relative;
  display: block;
}

.team-grid__item__img-outer {
  position: relative;
  z-index: 1;
  padding-bottom: 100%;

    @include mq(screen-md) {
      padding-bottom: 75%;
    }
}

.team-grid__item__img {
  position: absolute;
  z-index: 1;

  @include object-fit;
}

.team-grid__item__info {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  width: 100%;
  height: 100%;

    &:before {
      content: "";

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 100%;

        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
        opacity: 0.5;
        transition: opacity $animation-speed-md;
    }

}

.team-grid__item__link:hover {
  
  .team-grid__item__info:before {
    opacity: 0.75;
  }

}

.team-grid__item__text {
  position: absolute;
  top: 60%;
  left: 0;
  z-index: 2;

  width: 100%;
  padding: 0 $padding-sm;
}