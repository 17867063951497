// Brand colors

$color-black: #000000;
$color-grey: #999999;
$color-grey-light: #F5F5F5;

// Colour palette

$color-green: #00e493;
$color-green-dark: #03343f;
$color-purple: #7122e5;
$color-sky-blue: #9df6f9;
$color-red: #e82d5e;
$color-pink: #f9a2c5;
$color-yellow: #f9e153;
$color-blue: #4d7fff;
$color-orange: #ee7645;
$color-cream: #ecdec5;

$grad-green: linear-gradient(
  60deg,
    rgba(189, 249, 253, 1) 0%,
    rgba(0, 227, 146, 1) 17%,
    rgba(21, 161, 172, 1) 74%,
    rgba(26, 86, 132, 1) 100%
  );

$color-success: #70e000;
$color-pending: #ffd500;
$color-error: red;

$img-fade: 0.7;

// Social colours

$color-facebook: #3b5998;
$color-linkedin: #0e76a8;
$color-twitter: #1da1f2;
$color-whatsapp: #25D366;
$color-instagram: #f5f5f5;
$color-youtube: #FF0000;

$darken: 5%;

// Animation

$transition-lg: 0.5s ease-out;
$transition-md: 0.25s ease-out;
$transition-sm: 0.15s ease-out;

$animation-speed-sm: 0.15s;
$animation-speed-md: 0.25s;
$animation-speed-lg: 0.5s;

// Fonts

$bw-heavy: 'BwStretch-Heavy', Impact;
$larsseit-bold: 'Larsseit-Bold', Arial;
$larsseit-light: 'Larsseit-Light', Arial;

$title-size-xl: 15rem;
$title-size-lg: 10rem;
$title-size-md: 7.5rem;
$title-size-sm: 5rem;
$title-size-xs: 2.5rem;

$font-size-xl: 3rem;
$font-size-lg: 2.7rem;
$font-size-md: 2.1rem;
$font-size-sm: 1.8rem;
$font-size-xs: 1.5rem;
$font-size-xxs: 1.2rem;

$kerning-md: -0.2rem;
$kerning-sm: -0.1rem;

$line-height-lg: 2;
$line-height-md: 1.75;
$line-height-sm: 1.5;

// UI styles

$padding-global: 6rem;

$padding-xl: $padding-global*2;
$padding-lg: $padding-global;
$padding-md: $padding-global/2;
$padding-sm: $padding-global/3;
$padding-xs: $padding-global/4;

$border-radius-lg: 1rem;
$border-radius-md: 0.5rem;
$border-radius-sm: 0.25rem;

// $box-shadow-lg: 0 4px 8px rgba(0,0,0,0.1);

$box-shadow-lg: 0px 4px 16px rgba(89, 48, 15,0.05), 0px 8px 32px rgba(89, 48, 15,0.05);
$box-shadow-sm: 0px 2px 8px rgba(89, 48, 15,0.25);
$btn-hover-shadow: 0 4px 8px rgba(0,0,0,0.25);
// Reviews

$unchecked-star: '\2606';
$checked-star: '\2605';
$star-size: 5rem;