// =================================================
// STANDARD CONTENT STYLES
// =================================================

.std-content {
	width: 100%;

	& > *+* {
		margin: $padding-xs 0 0;
	}

	& > *+h2, > *+h3, & > *+h4, & > *+h5, & > *+h6 {
		margin: 3rem 0 0;
	}
	
	// Main headings

	h2, h3, h4, h5, h6 {
		font-family: $larsseit-bold;
	}

	h2 {
		font-size: $font-size-md;
		line-height: $line-height-sm;

			@include mq(screen-lg) {
				font-size: $font-size-lg;
			}

			strong {
				font-family: $bw-heavy;
				font-size: $title-size-sm;
				text-transform: uppercase;
				line-height: 1;
			}

	}

	h3 {
		font-size: $font-size-sm;
		line-height: $line-height-sm;

			@include mq(screen-lg) {
				font-size: $font-size-md;
			}

	}

	h4, h5, h6 {
		font-size: $font-size-sm;
		line-height: $line-height-md;
	}

	// Paragraphs

	p,ul,ol {
		//color: $color-black;

		a {
			color: $color-black;
			text-decoration: underline;

				&:hover {
					color: $color-green;
				}

		}

		strong {
			font-family: $larsseit-bold;
			//color: $color-black;
		}

		em {
			font-style: italic;
			color: $color-black;
		}
					
	}

	p {
		line-height: $line-height-md;
	}

	sup {
		font-size: 75%;
	}

	ul {
		list-style-type: disc;
		margin-left: 2rem;
	}

	ol {
		list-style-type: decimal;
		margin-left: 2rem;
	}

	ul ul {
		margin: 1rem 0 0 3rem;
		list-style-type: circle;
	}

	li {		
		line-height: $line-height-md;
	}

	li + li {
		margin-top: 1rem;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
		margin: $padding-lg auto;
	}

	a {
		color: inherit;
		text-decoration: underline;
	}
	
	hr {
		margin: $padding-md 0;
		border: 1px solid $color-green;
	}

	blockquote {
		padding: $padding-md 0;

			@include mq(screen-lg) {
				padding: $padding-lg 0;
			}

	}

	blockquote p {
		font-family: $bw-heavy;
		font-size: $title-size-xs;
		line-height: 1;
		text-transform: uppercase;

		background: $grad-green;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

			&:before {
				content: open-quote;
			}

			&:after {
				content: close-quote;
			}

			@include mq(screen-sm) {
				font-size: $title-size-sm;
			}

			@include mq(screen-lg) {
				font-size: $title-size-md;
			}

	}

	.video-embed {
		margin: $padding-lg 0;
	}

	.responsive-table {
		margin: $padding-md 0 $padding-lg;
	}

	> table {
		display: block;
		margin: $padding-md 0 $padding-lg;

		border-collapse: collapse;
		border-spacing: 0px;
	
		background-color: $color-grey-light;
		width: 100%;
	
			tr:not(:last-child) td {
				border-bottom: 1px solid white;
			}
	
			th {
				border-bottom: 3px solid $color-black;
	
				font-family: $larsseit-bold;
				color: $color-black;
			}
	
			th,td {
				padding: 1rem;
	
				font-size: $font-size-xs;
				line-height: $line-height-md;
				vertical-align: top;
				text-align: left;
			}
	
	
	}

	h2 + .video-embed,
	h2 + img,
	h3 + .video-embed,
	h3 + img,
	h4 + .video-embed,
	h4 + img,
	h5 + .video-embed
	h5 + img {
		margin: $padding-sm 0 $padding-lg;
	}

}

img.alignleft,
img.alignright {
	width: 50%;
	margin: $padding-lg auto;

		@include mq(screen-xxs) {
			width: 20%;
			clear: both;
			margin-top: $padding-md;
			margin-bottom: $padding-md;
		}

		@include mq(screen-lg) {
			width: 25%;
		}

}

img.alignleft {
	
	@include mq(screen-xxs) {
		float: left;
		margin-right: $padding-lg;
	}

}

img.alignright {
	
	@include mq(screen-xxs) {
		float: right;
		margin-left: $padding-lg;
	}

}

.wp-caption {
	position: relative;
	width: 100% !important;
	height: auto;
	margin: $padding-lg auto;

		img {
			position: relative;
			z-index: 1;

			margin: 0;
		}
		
}

p.wp-caption-text {
	position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;

	display: block;

	font-size: $font-size-xxs;
	text-align: right;
	color: white;


  width: 100%;
  padding: $padding-md $padding-sm $padding-sm;

  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
}