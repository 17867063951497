.archive-feed-col {
  @include mq(screen-lg) {
    flex: 1;
  }
}

.archive-feed {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: $padding-md;

  list-style-type: none;

    @include mq(screen-xs) {
      flex-direction: row;
    }

    @include mq(screen-lg) {
      gap: $padding-lg;
    }
    
}

.no-flexbox-gap {

  .archive-feed__item {
    margin: $padding-md;
  }

}

.archive-feed__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $padding-sm;

    @include mq(screen-xs) {
      width: calc(50% - 3rem);
    }

    @include mq(screen-sm) {
      width: calc(33.333% - 3rem);
    }

    @include mq(screen-lg) {
      width: calc(33.333% - 6rem);
    }

    @include mq(screen-lg) {
      flex-direction: row;
    }

}

.archive-feed__item__link {
  color: $color-black;
  line-height: $line-height-md;

    &:hover {
      color: $color-green;
    }

}

.archive-feed__item__img-outer {
  display: none;
  
    @include mq(screen-sm) {
      display: block;
      width: 8rem;
      height: 8rem;
      flex-shrink: 0;
    }

}

.no-flexbox-gap {
  
  .archive-feed__item__img-outer {
    
    @include mq(screen-sm) {
      margin: 0 0 $padding-sm;
    }

  }

}

.archive-feed__item__img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
}

.archive-feed__item__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}