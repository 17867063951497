.news-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 $padding-md;

    .title {
      margin: 0 0 $padding-md;
    }

    @include mq(screen-xs) {
      flex-direction: row;
      justify-content: space-between;
      
      margin: 0;
    }

}

.news-nav__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: $padding-sm;
  list-style-type: none;

    @include mq(screen-lg) {
      gap: $padding-md;
    }
    
}

.no-flexbox-gap {
  
  .news-nav__list {
    margin: 0 $padding-xs;
  }

}