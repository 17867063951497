.investor-nav__toggle {
  
  @include mq(screen-md) {
    display: none;
  }

}

.investor-nav__list {
  display: none;
  padding: $padding-md;
  border: 2px solid $color-black;

    &.is-open {
      display: block;
    }

    @include mq(screen-md) {
      display: block !important;
      padding: 0;
      border: none;
    }

}

.investor-content {
  display: flex;
  flex-direction: column;
  gap: $padding-lg;
  margin: 0 0 $padding-lg;
    
    @include mq(screen-md) {
      flex-direction: row;
      gap: $padding-md;
    }

    @include mq(screen-lg) {
      margin: 0 0 $padding-xl;
    }

}

.investor-nav {
  @include mq(screen-md) {
    width: calc(25% - 3rem);
    flex-shrink: 0;
  }
}

.investor-content-sections {
  
  @include mq(screen-md) {
    flex: 1;
  }

}

.no-flexbox-gap {
  
  .investor-content-sections {
    margin: $padding-lg 0 0;
  }

}