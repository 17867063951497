.nav-links {
  display: flex;
  justify-content: center;
  margin: $padding-lg 0 0;

    > *+* {
      display: block;
      margin: 0 1rem;
    }

    a {
      font-family: $larsseit-bold;
      color: $color-black;
      text-decoration: none;

        &:hover {
          color: $color-green;
        }
        
    }

    .current {
      font-family: $larsseit-bold;
      color: $color-green;
    }

}

