.hero-content {
  text-align: center;

    .supporting-text {
      
      @include mq(screen-sm) {
        padding: 0 25%;
      }

    }
   
}