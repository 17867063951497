// =================================================
// HELPER CLASSES
// =================================================

.video-promo {
  position: relative;
  padding: 0 0 $padding-lg;

  background-color: $color-black;

    @include mq(screen-sm) {
      padding: 0 $padding-md $padding-lg;
    }

    @include mq(screen-lg) {
      padding: 0 $padding-lg $padding-xl;
    }

    .title {
      position: relative;
      //top: 1.5rem;
      z-index: 2;

      width: 13ch;
      margin: 0 auto $padding-sm;

      text-shadow: 0px 10px 10px rgba(black, 0.15);

        @include mq(screen-sm) {
          top: 6rem;

          margin: 0 auto;
        }

    }

}

.video-promo__video {
  position: relative;
  z-index: 1;

    @include mq(screen-sm) {
      padding: $padding-md;
    }

    @include mq(screen-lg) {
      width: $screen-md;
      margin: 0 auto;
    }

    @include mq(screen-xl) {
      width: $screen-lg;
    }

    &:before {
      
      //@include mq(screen-sm) {
        content: "";

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 100%;

        background: url('img/bg-gradient.jpg') no-repeat center center;
        background-size: cover;
        filter: blur(20px);
      //}

    }

    .video-embed {
      position: relative;
      z-index: 2;
    }

}

.video-promo__text {
  margin: $padding-md 0 0;
  padding: 0 $padding-md;

    @include mq(screen-sm) {
      width: $screen-sm;
      margin: $padding-md auto 0;
    }

    @include mq(screen-lg) {
      padding: 0 $padding-lg;
      margin: $padding-lg auto 0;
    }

}

