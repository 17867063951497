.accordion {
  list-style-type: none;
}

.accordion__item + .accordion__item {
  margin: $padding-xs 0 0;
}

.accordion__item__toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: $padding-xs;

  border: none;
  background-color: $color-grey-light;

  color: $color-black;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      fill: currentColor;

      transform: rotate(90deg);
    }


    &:hover {
      background-color: $color-green;
    }

    &.is-active {
      background-color: $color-black;

      color: white;
    }

}

.accordion__item__content {
  display: none;
  padding: $padding-md;
  border: 2px solid $color-black;

    &.is-open {
      display: block;
    }
    
}