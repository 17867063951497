.text-img {
  display: flex;
  flex-direction: column;
  gap: $padding-lg;

  overflow: hidden;
  
  @include mq(screen-xs) {
    flex-direction: row;
  }

  @include mq(screen-lg) {
    //gap: $padding-xl;
    gap: 0;
  }

}

.text-img + .text-img {
  margin-top: $padding-lg;

    @include mq(screen-lg) {
      margin-top: $padding-xl;
    }
    
}

.text-img__img-outer {
  position: relative;
  
    @include mq(screen-xs) {
      flex: 1;
    }

}

.no-flexbox-gap {
  
  .text-img__img-outer {
    margin: 0 0 $padding-lg;
  }

}

.text-img__img {
  z-index: 1;

  @include flex-img;
}

.text-img__img__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  width: 100%;
  padding: $padding-md $padding-sm $padding-sm;

  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
}

.text-img__text-outer {
  padding: 0 $padding-md;
  
    @include mq(screen-xs) {
      padding: 0;
      flex: 1;
    }

}

.text-img__text {
  
  @include mq(screen-lg) {
    width: 640px;
    max-width: 100%;
  }

}

.text-img__logo {
  display: block;
  width: 7.5rem;
  height: auto;
  margin: 0 0 $padding-md;

    @include mq(screen-sm) {
      width: 10rem;
    }
    
}

// Variants

.text-img--default {
  
  .text-img__text {
    
    @include mq(screen-xs) {
      padding: $padding-md $padding-md 0;
    }

    @include mq(screen-lg) {
      padding: 0.5rem $padding-lg 0 $padding-xl;
    }

  }

}

.text-img--alt {
  
  .text-img__text-outer {
    
    @include mq(screen-xs) {
      order: 1;

      display: flex;
      justify-content: flex-end;
    }

  }

  .text-img__text {

    @include mq(screen-xs) {
      padding: $padding-md $padding-md 0;
    }

    @include mq(screen-lg) {
      padding: 0.5rem $padding-xl 0 $padding-lg;
    }

  }

  .text-img__img-outer {
    
    @include mq(screen-xs) {
      order: 2;
    }

  }

}

.text-img--white {

  .text-img__text {
    
    .btn {
      @extend .btn--black;
    }

  }

}

.text-img--black {

  .text-img__text {
    
    .btn {
      @extend .btn--white;
    }

  }

  .std-content {

    h2, h3, h4, h5, h6, p, ul {
      color: white;
    }

    a {
      color: white;
    }
    
  }

}

// Events specific

.events-promo {
  
  .text-img__img-outer {
    
    @include mq(screen-xs) {
      height: 100%;
    }

  }

  .text-img__img {
    
    @include mq(screen-xs) {
      height: 100%;
      object-fit: cover;
    }

  }

}

// Article headers

.article-header--black-white {
  background-color: $color-black;

  color: white;

    .date-stamp {
      color: $color-black;
      background-color: white;
    }

}

.article-header--green-dark-green {
  background-color: $color-green;

  color: $color-green-dark;

    .date-stamp {
      color: $color-green;
      background-color: $color-green-dark;
    }

}

.article-header--dark-green-green {
  background-color: $color-green-dark;

  color: $color-green;

    .date-stamp {
      color: $color-green-dark;
      background-color: $color-green;
    }

}

.article-header--purple-sky-blue {
  background-color: $color-purple;

  color: $color-sky-blue;

    .date-stamp {
      color: $color-purple;
      background-color: $color-sky-blue;
    }

}

.article-header--sky-blue-purple {
  background-color: $color-sky-blue;

  color: $color-purple;

    .date-stamp {
      color: $color-sky-blue;
      background-color: $color-purple;
    }

}

.article-header--red-pink {
  background-color: $color-red;

  color: $color-pink;

    .date-stamp {
      color: $color-red;
      background-color: $color-pink;
    }

}

.article-header--pink-red {
  background-color: $color-pink;

  color: $color-red;

    .date-stamp {
      color: $color-pink;
      background-color: $color-red;
    }

}

.article-header--yellow-blue {
  background-color: $color-yellow;

  color: $color-blue;

    .date-stamp {
      color: $color-yellow;
      background-color: $color-blue;
    }

}

.article-header--blue-yellow {
  background-color: $color-blue;

  color: $color-yellow;

    .date-stamp {
      color: $color-blue;
      background-color: $color-yellow;
    }

}

.article-header--orange-cream {
  background-color: $color-orange;

  color: $color-cream;

    .date-stamp {
      color: $color-orange;
      background-color: $color-cream;
    }

}

.article-header--cream-orange {
  background-color: $color-cream;

  color: $color-orange;

    .date-stamp {
      color: $color-cream;
      background-color: $color-orange;
    }

}