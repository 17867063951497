.news-feed-outer {
  
  @include mq(screen-lg) {
    display: flex;
    gap: $padding-lg;
  }

}

.news-feed__text {
  margin: 0 0 $padding-md;
  
  @include mq(screen-lg) {
    width: 30rem;
    margin: 0;
  }

}

.outer--bg-black .news-feed__text .std-content p {
  color: white;
}

.news-feed {
  display: flex;
  flex-direction: column;

  list-style-type: none;

    @include mq(screen-xs) {
      flex-direction: row;
      gap: $padding-sm;
    }

    @include mq(screen-lg) {
      flex: 1;
    }

}

.news-feed__item {
  position: relative;
}

.no-flexbox-gap {
  
  .news-feed__item + .news-feed__item {
    
    @include mq(screen-xs) {
      margin: 0 0 0 $padding-sm;
    }

  }

}

.news-feed__item {
  padding: 25rem 0 0;
}

.news-feed .news-feed__item {

  @include mq(screen-xs) {
    flex: 1;
  }

  @include mq(screen-lg) {
    padding: 35rem 0 0;
  }

  .news-feed__item__img {
    
    @include mq(screen-lg) {
      height: 30rem;
    }

  }

}

.news-feed .news-feed__item,
.block-slider__slide {
  
  &:nth-child(even) {
    background-color: $color-black;
      
      .news-feed__item__img {
        height: 100%;
        opacity: 0.5;
      }

      .btn {
        @extend .btn--white;
      }

  }

}

.news-feed__item__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  display: block;
  width: 100%;
  height: 20rem;

  object-fit: cover;
}

.news-feed__item__excerpt {
  position: relative;
  z-index: 2;
  padding: 0 $padding-lg $padding-md $padding-sm;
}

// Variants

.outer--bg-black {

  .title {
    color: white;
  }

  .news-feed__item:nth-child(odd) {

    .btn {
      @extend .btn--white;
    }

    .sub-title {
      color: white;
    }

    .excerpt-text {
      color: white;
    }

  }

  .news-feed__item:nth-child(even) {
    
    .news-feed__item__excerpt {
      color: white;
    }

  }

}

.outer--bg-white {

  .title {
    color: $color-black;
  }

  .news-feed__item:nth-child(odd) {

    .btn {
      @extend .btn--black;
    }

    .excerpt-text {
      color: $color-black;
    }

  }

  .news-feed__item:nth-child(even) {

    .news-feed__item__excerpt {
      color: white;
    }
    
    .btn {
      @extend .btn--white;
    }

  }
    
}

.news-feed-slider {

  .block-slider__slide:nth-child(odd) {

    .sub-title {
      color: white;
    }

    .excerpt-text {
      color: white;
    }

  }
   
  .block-slider__slide:nth-child(even) {
  
    .news-feed__item__excerpt {
      color: white;
    }

  }

}