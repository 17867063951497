// =================================================
// FONTS
// =================================================

@font-face {
  font-family: 'Larsseit-Bold';
  src: url('fonts/Larsseit-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Larsseit-Light';
  src: url('fonts/Larsseit-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BwStretch-Heavy';
  src: url('fonts/BwStretch-Heavy.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}