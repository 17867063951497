/*!
Theme Name: ZOO Digital Hello
Theme URI:
Author: Born + Raised
Author URI: http://www.bornandraised.com
Description: ZOO Digital WordPress theme
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: 
Tags:
*/
@font-face {
  font-family: "Larsseit-Bold";
  src: url("fonts/Larsseit-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Larsseit-Light";
  src: url("fonts/Larsseit-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "BwStretch-Heavy";
  src: url("fonts/BwStretch-Heavy.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html,
body {
  border: none;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Larsseit-Light", Arial;
  font-size: 1.8rem;
  color: #000000;
  background-color: white;
}
[id] {
  scroll-margin-top: 2rem;
}

h3 strong,
h4 strong,
h5 strong,
h6 strong {
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
address,
big,
cite,
code,
em,
font,
img,
small,
strike,
sub,
sup,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tr,
th,
td {
  border: none;
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
}

strong {
  font-weight: normal;
}

blockquote::before,
blockquote::after {
  content: "";
}

html {
  font-size: 62.5%;
  /* 1rem = 10px */
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  word-wrap: break-word;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0s !important;
    transition-duration: 0s !important;
  }
}
input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=date],
input[type=month],
input[type=time],
input[type=datetime],
input[type=datetime-local],
input[type=week],
input[type=number],
input[type=search],
input[type=tel],
input[type=color],
textarea,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  font-family: "Larsseit-Light", Arial;
}

select {
  font-family: "Larsseit-Light", Arial;
  font-size: inherit;
}

button {
  cursor: pointer;
}

.no-scroll {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

.screen-reader-text {
  display: none;
}

/*! Flickity v2.2.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

body.is-fixed {
  overflow: hidden;
}

body.admin-bar .pg-header {
  top: 3rem;
  margin: 3rem 0 0;
}

.main-content {
  position: relative;
  z-index: 1;
}

iframe {
  border: none;
}

@media (min-width: 1024px) {
  .mob-header,
.mob-nav-outer {
    display: none !important;
    visibility: hidden !important;
  }
}

.mob-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 3rem;
}

.mob-header__logo {
  display: block;
  width: 5rem;
  height: 5rem;
  visibility: visible;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.mob-nav-toggle {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: "BwStretch-Heavy", Impact;
  font-size: 2.1rem;
  text-transform: uppercase;
  border: none;
  background: none;
}
.mob-nav-toggle.is-active {
  color: #00e493;
}
.mob-nav-toggle svg {
  width: 3rem;
  height: 3rem;
  fill: currentColor;
}

.no-flexbox-gap .mob-nav-toggle svg {
  margin: 0 1rem 0 0;
}

.mob-nav-outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 3rem;
  background-color: #000000;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.mob-nav-outer.is-open {
  z-index: 2;
  visibility: visible;
  opacity: 1;
}

.mob-nav__list > li + li {
  margin: 1.5rem 0 0;
}
.mob-nav__list > li > a {
  font-family: "BwStretch-Heavy", Impact;
  font-size: 2.1rem;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .mob-nav__list > li > a {
    font-size: 2.7rem;
  }
}
.mob-nav__list > li > a.is-active {
  color: #00e493;
}
.mob-nav__list .sub-menu {
  display: none;
  padding: 1.5rem 0 0;
  margin: 0 0 0 2rem;
}
.mob-nav__list .sub-menu.is-open {
  display: block;
}
.mob-nav__list .sub-menu > li + li {
  margin: 1rem 0 0;
}
.mob-nav__list .sub-menu a {
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
}

.pg-header {
  display: none;
  visibility: hidden;
}
@media (min-width: 1024px) {
  .pg-header {
    position: absolute;
    top: 1rem;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 3rem;
    visibility: visible;
  }
}
@media (min-width: 1280px) {
  .pg-header {
    padding: 3rem 6rem;
  }
}
.pg-header .logout {
  position: absolute;
  top: 1rem;
  right: 3rem;
}

.pg-header__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  z-index: 1;
  display: block;
  width: 7.5rem;
  height: 7.5rem;
}
.pg-header__logo svg {
  transition: all 0.5s ease-in-out;
}
.pg-header__logo:hover svg {
  fill: #00e493;
}

.main-nav__list {
  display: flex;
  gap: 3rem;
  list-style-type: none;
}
@media (min-width: 1280px) {
  .main-nav__list {
    gap: 6rem;
  }
}
.main-nav__list a {
  text-decoration: none;
  cursor: pointer;
}

.has-hero .main-nav__list a {
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.75);
}

@media (min-width: 1024px) {
  .no-flexbox-gap .main-nav__list:first-of-type li {
    margin: 0 3rem 0 0;
  }
}
@media (min-width: 1024px) {
  .no-flexbox-gap .main-nav__list:last-of-type li {
    margin: 0 0 0 3rem;
  }
}

.sub-nav-outer {
  position: absolute;
  top: -100%;
  left: 0;
  z-index: 2;
  width: 100%;
  background-color: #000000;
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 4px 16px rgba(89, 48, 15, 0.05), 0px 8px 32px rgba(89, 48, 15, 0.05);
  opacity: 0;
}
@media (min-width: 1024px) {
  .sub-nav-outer {
    padding: 15rem 0 12rem;
  }
}
@media (min-width: 1280px) {
  .sub-nav-outer {
    padding: 20rem 0 12rem;
  }
}
.sub-nav-outer:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 3rem;
  background: linear-gradient(60deg, #bdf9fd 0%, #00e392 17%, #15a1ac 74%, #1a5684 100%);
}
.sub-nav-outer.is-open {
  top: 0;
  opacity: 1;
}
@media screen and (max-width: 1023px) {
  .sub-nav-outer.is-open {
    display: none;
  }
}

.sub-nav-content-outer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  padding: 0 3rem;
  transition: all 0.5s ease-out;
  transition-delay: 0.25s;
}
.sub-nav-content-outer.is-visible {
  visibility: visible;
  opacity: 1;
}
@media (min-width: 1024px) {
  .sub-nav-content-outer {
    top: 15rem;
  }
}
@media (min-width: 1280px) {
  .sub-nav-content-outer {
    top: 20rem;
  }
}
@media (min-width: 1280px) {
  .sub-nav-content-outer {
    padding: 0 6rem;
  }
}
@media (min-width: 1600px) {
  .sub-nav-content-outer {
    width: 1600px;
  }
}

.sub-nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 1024px) {
  .sub-nav-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.sub-nav-content__text {
  display: none;
}
@media (min-width: 1024px) {
  .sub-nav-content__text {
    display: block;
    width: 25%;
  }
}

@media (min-width: 1024px) {
  .sub-nav {
    width: 70%;
  }
}
@media (min-width: 1280px) {
  .sub-nav {
    width: 66.667%;
  }
}
@media (min-width: 1600px) {
  .sub-nav {
    width: 50%;
  }
}

.sub-nav__list {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  list-style-type: none;
}
.sub-nav__list li {
  width: calc(33.333% - 1.5rem);
  line-height: 1.75;
}
.sub-nav__list li.current-item a {
  text-decoration: underline;
}
.sub-nav__list a {
  color: white;
  text-decoration: none;
}
.sub-nav__list a:hover {
  color: #00e493;
}
.sub-nav__list li:first-child a {
  font-family: "Larsseit-Bold", Arial;
  color: #00e493;
}
.sub-nav__list li:first-child a:hover {
  color: white;
}

.sub-nav__list + .close-sub-nav {
  margin: 6rem 0 0;
}

.close-sub-nav {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background: none;
  color: #00e493;
}
.close-sub-nav svg {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.5rem 0 0;
  fill: currentColor;
}
.close-sub-nav:hover {
  color: white;
}

body.mob-nav-is-open .mob-header__logo {
  opacity: 0;
  visibility: hidden;
}
body.mob-nav-is-open li.current_page_item a {
  color: #00e493;
}

body.has-hero .main-nav__list a {
  color: white;
}
body.has-hero .main-nav__list a:hover {
  color: #00e493;
}
body.has-hero .pg-header__logo svg {
  fill: white;
}
body.has-hero .mob-header__logo svg {
  fill: white;
}
body.has-hero .mob-nav-toggle {
  color: white;
}

body.has-hero.mob-nav-is-open .mob-nav-toggle {
  color: #00e493;
}

body.no-hero.nav-is-open .main-nav__list a {
  color: white;
}
body.no-hero.nav-is-open .pg-header__logo svg {
  fill: white;
}

body.no-hero.nav-is-open .main-nav__list .active-menu a,
body.has-hero.nav-is-open .main-nav__list .active-menu a {
  color: #00e493;
}

body.no-hero .main-nav__list a {
  color: #000000;
}
body.no-hero .main-nav__list a:hover {
  color: #00e493;
}
body.no-hero .main-content {
  padding: 15rem 0 0;
}
@media (min-width: 1024px) {
  body.no-hero .main-content {
    padding: 20rem 0 0;
  }
}

.pg-footer-outer {
  position: relative;
  padding: 6rem 0;
}
@media (min-width: 768px) {
  .pg-footer-outer {
    padding: 12rem 0;
  }
}

@media (min-width: 768px) {
  body.services .pg-footer-outer {
    display: none;
  }
}

.pg-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}
@media (min-width: 1280px) {
  .pg-footer {
    gap: 6rem;
  }
}

.no-flexbox-gap .pg-footer > * + * {
  margin: 3rem 0 0;
}

.pg-footer__logo svg {
  width: 10rem;
  fill: #000000;
}

.pg-footer__nav__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
  list-style-type: none;
}
.pg-footer__nav__list a {
  text-decoration: none;
  color: #000000;
}
.pg-footer__nav__list a:hover {
  color: #00e493;
}

.no-flexbox-gap .pg-footer__nav__list li {
  margin: 1rem;
}

.pg-footer__email {
  font-family: "Larsseit-Bold", Arial;
  font-size: 2.1rem;
  color: #000000;
}
@media (min-width: 1280px) {
  .pg-footer__email {
    font-size: 2.7rem;
  }
}
.pg-footer__email:hover {
  color: #00e493;
}

.social {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 650px) {
  .social {
    flex-direction: row;
    justify-content: center;
  }
}

.social__title {
  margin: 0 0 2rem;
}
@media (min-width: 650px) {
  .social__title {
    margin: 0 3rem 0 0;
  }
}

.social__list {
  display: flex;
  list-style-type: none;
}

.social__list__item + .social__list__item {
  margin: 0 0 0 3rem;
}

.social__list__link {
  display: block;
  width: 3rem;
  height: 3rem;
  padding: 0;
  background: #F5F5F5;
}
.social__list__link svg {
  width: 3rem;
  height: 3rem;
  fill: black;
}
.social__list__link:hover {
  background: linear-gradient(60deg, #bdf9fd 0%, #00e392 17%, #15a1ac 74%, #1a5684 100%);
}
.social__list__link:hover svg {
  fill: white;
}

.outer {
  position: relative;
}

.outer--bg-white {
  background-color: white;
}

.outer--bg-black {
  background-color: #000000;
}

.outer--bg-grey-light {
  background-color: #F5F5F5;
}

.outer--bg-grey-light {
  background-color: #F5F5F5;
}

.inner {
  width: 100%;
  margin: 0 auto;
  padding: 0 3rem;
}

@media (min-width: 768px) {
  .inner--sm {
    width: 768px;
  }
}

@media (min-width: 1024px) {
  .inner--md {
    width: 1024px;
  }
}
@media (min-width: 1280px) {
  .inner--md {
    padding: 0 6rem;
  }
}

@media (min-width: 1280px) {
  .inner--lg {
    width: 1280px;
  }
}
@media (min-width: 1280px) {
  .inner--lg {
    padding: 0 6rem;
  }
}

@media (min-width: 1600px) {
  .inner--xl {
    width: 1600px;
  }
}
@media (min-width: 1280px) {
  .inner--xl {
    padding: 0 6rem;
  }
}

@media (min-width: 1800px) {
  .inner--xxl {
    width: 1800px;
  }
}

@media (min-width: 650px) {
  .dashboard {
    display: flex;
    justify-content: center;
  }
}

.dashboard__col {
  padding: 3rem 0;
}
@media (min-width: 650px) {
  .dashboard__col {
    width: 50%;
    padding: 0 3rem;
  }
}

.dashboard__col + .dashboard__col {
  border-top: 1px solid #999999;
}
@media (min-width: 650px) {
  .dashboard__col + .dashboard__col {
    border-top: none;
    border-left: 1px solid #999999;
  }
}

.mt-xs {
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  .mt-xs {
    margin-top: 1rem;
  }
}

.mt-sm {
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .mt-sm {
    margin-top: 2rem;
  }
}

.mt-md {
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .mt-md {
    margin-top: 3rem;
  }
}

.mt-lg {
  margin-top: 3rem;
}
@media (min-width: 768px) {
  .mt-lg {
    margin-top: 6rem;
  }
}

.mt-xl {
  margin-top: 6rem;
}
@media (min-width: 768px) {
  .mt-xl {
    margin-top: 12rem;
  }
}

.mb-xs {
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .mb-xs {
    margin-bottom: 1rem;
  }
}

.mb-sm {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .mb-sm {
    margin-bottom: 2rem;
  }
}

.mb-md {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .mb-md {
    margin-bottom: 3rem;
  }
}

.mb-lg {
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .mb-lg {
    margin-bottom: 6rem;
  }
}

.mb-xl {
  margin-bottom: 6rem;
}
@media (min-width: 768px) {
  .mb-xl {
    margin-bottom: 12rem;
  }
}

.mtb-xs {
  margin: 0.5rem 0;
}
@media (min-width: 768px) {
  .mtb-xs {
    margin: 1rem 0;
  }
}

.mtb-sm {
  margin: 1rem 0;
}
@media (min-width: 768px) {
  .mtb-sm {
    margin: 2rem 0;
  }
}

.mtb-md {
  margin: 2rem 0;
}
@media (min-width: 768px) {
  .mtb-md {
    margin: 3rem 0;
  }
}

.mtb-lg {
  margin: 3rem 0;
}
@media (min-width: 768px) {
  .mtb-lg {
    margin: 6rem 0;
  }
}

.mtb-xl {
  margin: 6rem 0;
}
@media (min-width: 768px) {
  .mtb-xl {
    margin: 12rem 0;
  }
}

.pt-xs {
  padding-top: 0.5rem;
}
@media (min-width: 768px) {
  .pt-xs {
    padding-top: 1rem;
  }
}

.pt-sm {
  padding-top: 1rem;
}
@media (min-width: 768px) {
  .pt-sm {
    padding-top: 2rem;
  }
}

.pt-md {
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .pt-md {
    padding-top: 3rem;
  }
}

.pt-lg {
  padding-top: 3rem;
}
@media (min-width: 768px) {
  .pt-lg {
    padding-top: 6rem;
  }
}

.pt-xl {
  padding-top: 6rem;
}
@media (min-width: 768px) {
  .pt-xl {
    padding-top: 12rem;
  }
}

.pb-xs {
  padding-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .pb-xs {
    padding-bottom: 1rem;
  }
}

.pb-sm {
  padding-bottom: 1rem;
}
@media (min-width: 768px) {
  .pb-sm {
    padding-bottom: 2rem;
  }
}

.pb-md {
  padding-bottom: 2rem;
}
@media (min-width: 768px) {
  .pb-md {
    padding-bottom: 3rem;
  }
}

.pb-lg {
  padding-bottom: 3rem;
}
@media (min-width: 768px) {
  .pb-lg {
    padding-bottom: 6rem;
  }
}

.pb-xl {
  padding-bottom: 6rem;
}
@media (min-width: 768px) {
  .pb-xl {
    padding-bottom: 12rem;
  }
}

.ptb-xs {
  padding: 0.5rem 0;
}
@media (min-width: 768px) {
  .ptb-xs {
    padding: 1rem 0;
  }
}

.ptb-sm {
  padding: 1rem 0;
}
@media (min-width: 768px) {
  .ptb-sm {
    padding: 2rem 0;
  }
}

.ptb-md {
  padding: 2rem 0;
}
@media (min-width: 768px) {
  .ptb-md {
    padding: 3rem 0;
  }
}

.ptb-lg {
  padding: 3rem 0;
}
@media (min-width: 768px) {
  .ptb-lg {
    padding: 6rem 0;
  }
}

.ptb-xl {
  padding: 6rem 0;
}
@media (min-width: 768px) {
  .ptb-xl {
    padding: 12rem 0;
  }
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.for-print-only {
  display: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .hide-from-ie {
    display: none;
  }
}

.video-embed {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
}
.video-embed iframe,
.video-embed object,
.video-embed embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.flex-img {
  position: relative;
}

.flex-img img {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: auto;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .flex-img img {
    flex-shrink: 0;
  }
}

.flex-img__img-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding: 3rem 2rem 2rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
}

.no-sr {
  display: none;
}

@media (min-width: 768px) {
  .js-fade-up {
    transform: translateY(6rem);
    opacity: 0;
    transition: transform 0.5s ease-in, opacity 0.75s ease-in;
  }
  .js-fade-up.has-animated {
    transform: translateY(0);
    opacity: 1;
  }

  .js-fade-down {
    transform: translateY(-3rem);
    opacity: 0;
    transition: transform 0.5s ease-in, opacity 0.75s ease-in;
  }
  .js-fade-down.has-animated {
    transform: translateY(0);
    opacity: 1;
  }

  .js-fade-left {
    transform: translateX(6rem);
    opacity: 0;
    transition: transform 0.5s ease-in, opacity 0.75s ease-in;
  }
  .js-fade-left.has-animated {
    transform: translateY(0);
    opacity: 1;
  }

  .js-fade-right {
    transform: translateX(-6rem);
    opacity: 0;
    transition: transform 0.5s ease-in, opacity 0.75s ease-in;
  }
  .js-fade-right.has-animated {
    transform: translateY(0);
    opacity: 1;
  }

  .js-fade-in {
    opacity: 0;
    transition: opacity 0.75s ease-in;
  }
  .js-fade-in.has-animated {
    opacity: 1;
  }

  .js-fade-in-slow {
    opacity: 0;
    transition: opacity 1.5s ease-in;
  }
  .js-fade-in-slow.has-animated {
    opacity: 1;
  }
}
.std-content {
  width: 100%;
}
.std-content > * + * {
  margin: 1.5rem 0 0;
}
.std-content > * + h2, .std-content > * + h3, .std-content > * + h4, .std-content > * + h5, .std-content > * + h6 {
  margin: 3rem 0 0;
}
.std-content h2, .std-content h3, .std-content h4, .std-content h5, .std-content h6 {
  font-family: "Larsseit-Bold", Arial;
}
.std-content h2 {
  font-size: 2.1rem;
  line-height: 1.5;
}
@media (min-width: 1280px) {
  .std-content h2 {
    font-size: 2.7rem;
  }
}
.std-content h2 strong {
  font-family: "BwStretch-Heavy", Impact;
  font-size: 5rem;
  text-transform: uppercase;
  line-height: 1;
}
.std-content h3 {
  font-size: 1.8rem;
  line-height: 1.5;
}
@media (min-width: 1280px) {
  .std-content h3 {
    font-size: 2.1rem;
  }
}
.std-content h4, .std-content h5, .std-content h6 {
  font-size: 1.8rem;
  line-height: 1.75;
}
.std-content p a, .std-content ul a, .std-content ol a {
  color: #000000;
  text-decoration: underline;
}
.std-content p a:hover, .std-content ul a:hover, .std-content ol a:hover {
  color: #00e493;
}
.std-content p strong, .std-content ul strong, .std-content ol strong {
  font-family: "Larsseit-Bold", Arial;
}
.std-content p em, .std-content ul em, .std-content ol em {
  font-style: italic;
  color: #000000;
}
.std-content p {
  line-height: 1.75;
}
.std-content sup {
  font-size: 75%;
}
.std-content ul {
  list-style-type: disc;
  margin-left: 2rem;
}
.std-content ol {
  list-style-type: decimal;
  margin-left: 2rem;
}
.std-content ul ul {
  margin: 1rem 0 0 3rem;
  list-style-type: circle;
}
.std-content li {
  line-height: 1.75;
}
.std-content li + li {
  margin-top: 1rem;
}
.std-content img {
  display: block;
  width: 100%;
  height: auto;
  margin: 6rem auto;
}
.std-content a {
  color: inherit;
  text-decoration: underline;
}
.std-content hr {
  margin: 3rem 0;
  border: 1px solid #00e493;
}
.std-content blockquote {
  padding: 3rem 0;
}
@media (min-width: 1280px) {
  .std-content blockquote {
    padding: 6rem 0;
  }
}
.std-content blockquote p {
  font-family: "BwStretch-Heavy", Impact;
  font-size: 2.5rem;
  line-height: 1;
  text-transform: uppercase;
  background: linear-gradient(60deg, #bdf9fd 0%, #00e392 17%, #15a1ac 74%, #1a5684 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.std-content blockquote p:before {
  content: open-quote;
}
.std-content blockquote p:after {
  content: close-quote;
}
@media (min-width: 768px) {
  .std-content blockquote p {
    font-size: 5rem;
  }
}
@media (min-width: 1280px) {
  .std-content blockquote p {
    font-size: 7.5rem;
  }
}
.std-content .video-embed {
  margin: 6rem 0;
}
.std-content .responsive-table {
  margin: 3rem 0 6rem;
}
.std-content > table {
  display: block;
  margin: 3rem 0 6rem;
  border-collapse: collapse;
  border-spacing: 0px;
  background-color: #F5F5F5;
  width: 100%;
}
.std-content > table tr:not(:last-child) td {
  border-bottom: 1px solid white;
}
.std-content > table th {
  border-bottom: 3px solid #000000;
  font-family: "Larsseit-Bold", Arial;
  color: #000000;
}
.std-content > table th, .std-content > table td {
  padding: 1rem;
  font-size: 1.5rem;
  line-height: 1.75;
  vertical-align: top;
  text-align: left;
}
.std-content h2 + .video-embed,
.std-content h2 + img,
.std-content h3 + .video-embed,
.std-content h3 + img,
.std-content h4 + .video-embed,
.std-content h4 + img,
.std-content h5 + .video-embed h5 + img {
  margin: 2rem 0 6rem;
}

img.alignleft,
img.alignright {
  width: 50%;
  margin: 6rem auto;
}
@media (min-width: 450px) {
  img.alignleft,
img.alignright {
    width: 20%;
    clear: both;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
@media (min-width: 1280px) {
  img.alignleft,
img.alignright {
    width: 25%;
  }
}

@media (min-width: 450px) {
  img.alignleft {
    float: left;
    margin-right: 6rem;
  }
}

@media (min-width: 450px) {
  img.alignright {
    float: right;
    margin-left: 6rem;
  }
}

.wp-caption {
  position: relative;
  width: 100% !important;
  height: auto;
  margin: 6rem auto;
}
.wp-caption img {
  position: relative;
  z-index: 1;
  margin: 0;
}

p.wp-caption-text {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: block;
  font-size: 1.2rem;
  text-align: right;
  color: white;
  width: 100%;
  padding: 3rem 2rem 2rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
}

.btn, .tml-links a {
  position: relative;
  display: inline-block;
  padding: 0 0 0.5rem;
  font-family: "Larsseit-Bold", Arial;
  font-size: 1.8rem;
  text-decoration: none;
  overflow: hidden;
  border: none;
  background: none;
  cursor: pointer;
}
.btn:before, .tml-links a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 2px;
}
.btn:after, .tml-links a:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 100%;
  z-index: 2;
  width: 100%;
  height: 2px;
  transition: right 0.15s;
}
.btn:hover:after, .tml-links a:hover:after {
  right: 0;
}

.btn--white, .text-img--black .text-img__text .btn, .text-img--black .text-img__text .tml-links a, .tml-links .text-img--black .text-img__text a, .outer--bg-white .news-feed__item:nth-child(even) .btn, .outer--bg-white .news-feed__item:nth-child(even) .tml-links a, .tml-links .outer--bg-white .news-feed__item:nth-child(even) a, .outer--bg-black .news-feed__item:nth-child(odd) .btn, .outer--bg-black .news-feed__item:nth-child(odd) .tml-links a, .tml-links .outer--bg-black .news-feed__item:nth-child(odd) a, .news-feed .news-feed__item:nth-child(even) .btn, .news-feed .news-feed__item:nth-child(even) .tml-links a, .tml-links .news-feed .news-feed__item:nth-child(even) a,
.block-slider__slide:nth-child(even) .btn,
.block-slider__slide:nth-child(even) .tml-links a,
.tml-links .block-slider__slide:nth-child(even) a {
  color: white;
}
.btn--white:before, .text-img--black .text-img__text .btn:before, .text-img--black .text-img__text .tml-links a:before, .tml-links .text-img--black .text-img__text a:before, .outer--bg-white .news-feed__item:nth-child(even) .btn:before, .outer--bg-white .news-feed__item:nth-child(even) .tml-links a:before, .tml-links .outer--bg-white .news-feed__item:nth-child(even) a:before, .outer--bg-black .news-feed__item:nth-child(odd) .btn:before, .outer--bg-black .news-feed__item:nth-child(odd) .tml-links a:before, .tml-links .outer--bg-black .news-feed__item:nth-child(odd) a:before, .news-feed .news-feed__item:nth-child(even) .btn:before, .news-feed .news-feed__item:nth-child(even) .tml-links a:before, .tml-links .news-feed .news-feed__item:nth-child(even) a:before,
.block-slider__slide:nth-child(even) .btn:before,
.block-slider__slide:nth-child(even) .tml-links a:before,
.tml-links .block-slider__slide:nth-child(even) a:before {
  background-color: #00e493;
}
.btn--white:after, .text-img--black .text-img__text .btn:after, .text-img--black .text-img__text .tml-links a:after, .tml-links .text-img--black .text-img__text a:after, .outer--bg-white .news-feed__item:nth-child(even) .btn:after, .outer--bg-white .news-feed__item:nth-child(even) .tml-links a:after, .tml-links .outer--bg-white .news-feed__item:nth-child(even) a:after, .outer--bg-black .news-feed__item:nth-child(odd) .btn:after, .outer--bg-black .news-feed__item:nth-child(odd) .tml-links a:after, .tml-links .outer--bg-black .news-feed__item:nth-child(odd) a:after, .news-feed .news-feed__item:nth-child(even) .btn:after, .news-feed .news-feed__item:nth-child(even) .tml-links a:after, .tml-links .news-feed .news-feed__item:nth-child(even) a:after,
.block-slider__slide:nth-child(even) .btn:after,
.block-slider__slide:nth-child(even) .tml-links a:after,
.tml-links .block-slider__slide:nth-child(even) a:after {
  background-color: white;
}

.btn--black, .tml-links a, .text-img--white .text-img__text .btn, .text-img--white .text-img__text .tml-links a, .tml-links .text-img--white .text-img__text a, .outer--bg-white .news-feed__item:nth-child(odd) .btn, .outer--bg-white .news-feed__item:nth-child(odd) .tml-links a, .tml-links .outer--bg-white .news-feed__item:nth-child(odd) a {
  color: #000000;
}
.btn--black:before, .tml-links a:before, .text-img--white .text-img__text .btn:before, .text-img--white .text-img__text .tml-links a:before, .tml-links .text-img--white .text-img__text a:before, .outer--bg-white .news-feed__item:nth-child(odd) .btn:before, .outer--bg-white .news-feed__item:nth-child(odd) .tml-links a:before, .tml-links .outer--bg-white .news-feed__item:nth-child(odd) a:before {
  background-color: #00e493;
}
.btn--black:after, .tml-links a:after, .text-img--white .text-img__text .btn:after, .text-img--white .text-img__text .tml-links a:after, .tml-links .text-img--white .text-img__text a:after, .outer--bg-white .news-feed__item:nth-child(odd) .btn:after, .outer--bg-white .news-feed__item:nth-child(odd) .tml-links a:after, .tml-links .outer--bg-white .news-feed__item:nth-child(odd) a:after {
  background-color: #000000;
}

.btn-list {
  list-style-type: none;
}

.btn-list__item + .btn-list__item {
  margin: 2rem 0 0;
}

.btn-list__item--current .btn:after, .btn-list__item--current .tml-links a:after, .tml-links .btn-list__item--current a:after {
  left: 0;
}

.btn-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
}

.btn-group__item {
  padding: 1rem;
}

.title {
  font-family: "BwStretch-Heavy", Impact;
  text-transform: uppercase;
  line-height: 1;
}

.title a {
  text-decoration: none;
  color: inherit;
}

.title--xl {
  font-size: 5rem;
}
@media (min-width: 768px) {
  .title--xl {
    font-size: 7.5rem;
  }
}
@media (min-width: 1280px) {
  .title--xl {
    font-size: 15rem;
    letter-spacing: -0.2rem;
  }
}

.title--lg {
  font-size: 5rem;
}
@media (min-width: 768px) {
  .title--lg {
    font-size: 7.5rem;
  }
}
@media (min-width: 1280px) {
  .title--lg {
    font-size: 10rem;
    letter-spacing: -0.2rem;
  }
}

.title--md {
  font-size: 5rem;
}
@media (min-width: 768px) {
  .title--md {
    font-size: 7.5rem;
  }
}

.title--sm {
  font-size: 2.5rem;
}
@media (min-width: 768px) {
  .title--sm {
    font-size: 5rem;
  }
}

.title--white {
  color: white;
}

.sub-title {
  font-family: "Larsseit-Bold", Arial;
  font-size: 2.1rem;
  line-height: 1.5;
}

a.sub-title {
  display: block;
  text-decoration: none;
}

.sub-title--sm {
  font-size: 1.5rem;
}

.sub-title--md {
  font-size: 1.8rem;
}
@media (min-width: 1280px) {
  .sub-title--md {
    font-size: 2.1rem;
  }
}

.sub-title--lg {
  font-size: 2.1rem;
}
@media (min-width: 1280px) {
  .sub-title--lg {
    font-size: 2.7rem;
  }
}

.sub-title--black {
  color: #000000;
}

a.sub-title--black:hover {
  color: #00e493;
}

.sub-title--white {
  color: white;
}

.supporting-text {
  font-size: 1.8rem;
  line-height: 1.75;
}
@media (min-width: 1280px) {
  .supporting-text {
    font-size: 2.1rem;
  }
}
.supporting-text strong {
  font-family: "Larsseit-Bold", Arial;
}

.supporting-text--black {
  color: #000000;
}

.supporting-text--grey {
  color: #999999;
}

.supporting-text--white {
  color: white;
}

.excerpt-text {
  font-size: 1.5rem;
  line-height: 1.75;
}

.excerpt-text--grey {
  color: #999999;
}

.excerpt-text--white {
  color: white;
}

.img-caption-text {
  font-size: 1.2rem;
  opacity: 0.5;
}

.img-caption-text--white {
  color: white;
}

.video-promo {
  position: relative;
  padding: 0 0 6rem;
  background-color: #000000;
}
@media (min-width: 768px) {
  .video-promo {
    padding: 0 3rem 6rem;
  }
}
@media (min-width: 1280px) {
  .video-promo {
    padding: 0 6rem 12rem;
  }
}
.video-promo .title {
  position: relative;
  z-index: 2;
  width: 13ch;
  margin: 0 auto 2rem;
  text-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
}
@media (min-width: 768px) {
  .video-promo .title {
    top: 6rem;
    margin: 0 auto;
  }
}

.video-promo__video {
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .video-promo__video {
    padding: 3rem;
  }
}
@media (min-width: 1280px) {
  .video-promo__video {
    width: 1024px;
    margin: 0 auto;
  }
}
@media (min-width: 1600px) {
  .video-promo__video {
    width: 1280px;
  }
}
.video-promo__video:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url("img/bg-gradient.jpg") no-repeat center center;
  background-size: cover;
  filter: blur(20px);
}
.video-promo__video .video-embed {
  position: relative;
  z-index: 2;
}

.video-promo__text {
  margin: 3rem 0 0;
  padding: 0 3rem;
}
@media (min-width: 768px) {
  .video-promo__text {
    width: 768px;
    margin: 3rem auto 0;
  }
}
@media (min-width: 1280px) {
  .video-promo__text {
    padding: 0 6rem;
    margin: 6rem auto 0;
  }
}

.services-promo {
  padding: 6rem 0;
  overflow: hidden;
}
@media (min-width: 768px) {
  .services-promo {
    display: flex;
    justify-content: space-between;
    min-height: 75vh;
  }
}

.services-promo__imgs {
  display: none;
}
@media (min-width: 768px) {
  .services-promo__imgs {
    position: relative;
    display: block;
    width: 60%;
  }
}

@media (min-width: 768px) {
  .services-promo__img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity 0.5s;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.services-promo__img.is-hidden {
  opacity: 0;
}

.services-promo__info {
  padding: 0 3rem;
}
@media (min-width: 768px) {
  .services-promo__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
}

.services-promo__text {
  margin: 0 0 3rem;
}
@media (min-width: 768px) {
  .services-promo__text {
    margin: 0 0 25rem;
  }
}

.services-promo__list {
  list-style-type: none;
}

.services-promo__list__item + .services-promo__list__item {
  margin: 3rem 0 0;
}
@media (min-width: 768px) {
  .services-promo__list__item + .services-promo__list__item {
    margin: 0;
  }
}

.services-promo__list__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
}
@media (min-width: 768px) {
  .services-promo__list__link {
    padding: 1.5rem 0;
  }
}
.services-promo__list__link span {
  font-family: "Larsseit-Bold", Arial;
  font-size: 1.8rem;
  color: #000000;
}
@media (min-width: 1280px) {
  .services-promo__list__link span {
    font-size: 2.1rem;
    transition: all 0.25s;
  }
}
.services-promo__list__link svg {
  width: 1.5rem;
  fill: #000000;
}
@media (min-width: 1280px) {
  .services-promo__list__link svg {
    fill: none;
    transform: translateX(-2.5rem);
    transition: all 0.25s;
  }
}
@media (min-width: 1280px) {
  .services-promo__list__link:hover span {
    background: linear-gradient(60deg, #bdf9fd 0%, #00e392 17%, #15a1ac 74%, #1a5684 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .services-promo__list__link:hover svg {
    fill: #000000;
    transform: translateX(0);
  }
}

.slider-control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background: none;
  border: none;
}
.slider-control svg {
  width: 4rem;
  fill: white;
  transition: fill 0.15s;
}
.slider-control:hover svg {
  fill: #00e493;
}

.slider-control--previous svg {
  transform: rotate(-180deg);
}

.block-slider__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  text-align: center;
}
@media (min-width: 650px) {
  .block-slider__header {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
}

.no-flexbox-gap .block-slider__header .title {
  margin: 0 0 2rem;
}

.block-slider__overflow {
  position: relative;
  overflow: hidden;
}
@media (min-width: 650px) {
  .block-slider__overflow:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 20rem;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, black 100%);
  }
}
@media (min-width: 1280px) {
  .block-slider__overflow:after {
    width: 50rem;
  }
}

.disabled .block-slider__overflow:after {
  display: none;
}

.block-slider__slides {
  position: relative;
  z-index: 1;
}

.block-slider__slide {
  width: 100%;
  margin-right: 2rem;
}
@media (min-width: 650px) {
  .block-slider__slide {
    width: calc(50% - 2rem);
  }
}
@media (min-width: 1024px) {
  .block-slider__slide {
    width: calc(33.333% - 2rem);
  }
}

.block-slider__title {
  font-family: "Larsseit-Bold", Arial;
  font-size: 1.8rem;
  line-height: 1.5;
  color: white;
}
@media (min-width: 768px) {
  .block-slider__title {
    font-size: 2.1rem;
  }
}

.block-slider__text {
  font-size: 1.5rem;
  line-height: 1.75;
  color: white;
}

.block-slider__controls {
  display: flex;
  gap: 1rem;
}

.no-flexbox-gap .slider-control--previous {
  margin: 0 1rem 0 0;
}

@media (min-width: 1280px) {
  .news-feed-outer {
    display: flex;
    gap: 6rem;
  }
}

.news-feed__text {
  margin: 0 0 3rem;
}
@media (min-width: 1280px) {
  .news-feed__text {
    width: 30rem;
    margin: 0;
  }
}

.outer--bg-black .news-feed__text .std-content p {
  color: white;
}

.news-feed {
  display: flex;
  flex-direction: column;
  list-style-type: none;
}
@media (min-width: 650px) {
  .news-feed {
    flex-direction: row;
    gap: 2rem;
  }
}
@media (min-width: 1280px) {
  .news-feed {
    flex: 1;
  }
}

.news-feed__item {
  position: relative;
}

@media (min-width: 650px) {
  .no-flexbox-gap .news-feed__item + .news-feed__item {
    margin: 0 0 0 2rem;
  }
}

.news-feed__item {
  padding: 25rem 0 0;
}

@media (min-width: 650px) {
  .news-feed .news-feed__item {
    flex: 1;
  }
}
@media (min-width: 1280px) {
  .news-feed .news-feed__item {
    padding: 35rem 0 0;
  }
}
@media (min-width: 1280px) {
  .news-feed .news-feed__item .news-feed__item__img {
    height: 30rem;
  }
}

.news-feed .news-feed__item:nth-child(even),
.block-slider__slide:nth-child(even) {
  background-color: #000000;
}
.news-feed .news-feed__item:nth-child(even) .news-feed__item__img,
.block-slider__slide:nth-child(even) .news-feed__item__img {
  height: 100%;
  opacity: 0.5;
}
.news-feed__item__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 20rem;
  object-fit: cover;
}

.news-feed__item__excerpt {
  position: relative;
  z-index: 2;
  padding: 0 6rem 3rem 2rem;
}

.outer--bg-black .title {
  color: white;
}
.outer--bg-black .news-feed__item:nth-child(odd) .sub-title {
  color: white;
}
.outer--bg-black .news-feed__item:nth-child(odd) .excerpt-text {
  color: white;
}
.outer--bg-black .news-feed__item:nth-child(even) .news-feed__item__excerpt {
  color: white;
}

.outer--bg-white .title {
  color: #000000;
}
.outer--bg-white .news-feed__item:nth-child(odd) .excerpt-text {
  color: #000000;
}
.outer--bg-white .news-feed__item:nth-child(even) .news-feed__item__excerpt {
  color: white;
}
.news-feed-slider .block-slider__slide:nth-child(odd) .sub-title {
  color: white;
}
.news-feed-slider .block-slider__slide:nth-child(odd) .excerpt-text {
  color: white;
}
.news-feed-slider .block-slider__slide:nth-child(even) .news-feed__item__excerpt {
  color: white;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 3rem;
  background-color: rgba(255, 255, 255, 0.05);
}
@media (min-width: 650px) {
  .stat-item {
    height: 100%;
  }
}
@media (min-width: 1280px) {
  .stat-item {
    padding: 6rem;
  }
}

.stat-item__icon {
  margin: 0 0 3rem;
}
.stat-item__icon img,
.stat-item__icon svg {
  display: block;
  width: 5rem;
  height: 5rem;
}
@media (min-width: 1280px) {
  .stat-item__icon img,
.stat-item__icon svg {
    width: 10rem;
    height: 10rem;
  }
}

.stat-item__text {
  text-align: center;
}

.media-carousel-outer {
  position: relative;
  padding: 3rem 0;
  background-color: #000000;
}
@media (min-width: 768px) {
  .media-carousel-outer {
    padding: 3rem 0 6rem;
  }
}
@media (min-width: 1280px) {
  .media-carousel-outer {
    padding: 3rem 0 12rem;
  }
}
.media-carousel-outer .title {
  position: relative;
  top: 1.5rem;
  z-index: 2;
  max-width: 1024px;
  margin: 0 auto;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
@media (min-width: 1280px) {
  .media-carousel-outer .title {
    top: 5rem;
  }
}

.media-carousel {
  position: relative;
}
@media (min-width: 1600px) {
  .media-carousel {
    width: 1600px;
    margin: 0 auto;
  }
}
.media-carousel:before, .media-carousel:after {
  content: "";
  position: absolute;
  top: 0;
  z-index: 1;
  width: 10rem;
  height: 100%;
}
@media (min-width: 1280px) {
  .media-carousel:before, .media-carousel:after {
    width: 20rem;
  }
}
.media-carousel:before {
  left: 0;
  background: linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0) 100%);
}
.media-carousel:after {
  right: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, black 100%);
}
.media-carousel .slider-control {
  position: absolute;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
}
.media-carousel .slider-control--previous {
  left: 2rem;
}
@media (min-width: 768px) {
  .media-carousel .slider-control--previous {
    left: 3rem;
  }
}
@media (min-width: 1280px) {
  .media-carousel .slider-control--previous {
    left: 6rem;
  }
}
.media-carousel .slider-control--next {
  right: 2rem;
}
@media (min-width: 768px) {
  .media-carousel .slider-control--next {
    right: 3rem;
  }
}
@media (min-width: 1280px) {
  .media-carousel .slider-control--next {
    right: 6rem;
  }
}

.media-carousel__slide {
  width: calc(100% - 5rem);
  margin: 0 0.5rem;
}
@media (min-width: 768px) {
  .media-carousel__slide {
    width: calc(100% - 10rem);
  }
}
@media (min-width: 1280px) {
  .media-carousel__slide {
    width: 1024px;
  }
}
@media (min-width: 1600px) {
  .media-carousel__slide {
    width: 1280px;
  }
}

.media-carousel__slide__media {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
}
.media-carousel__slide__media iframe,
.media-carousel__slide__media object,
.media-carousel__slide__media embed,
.media-carousel__slide__media img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.media-carousel__slide__media img {
  z-index: 1;
  object-fit: cover;
  object-position: center;
}

.media-carousel__slide__img-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding: 3rem 2rem 2rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
}

.media-carousel__text {
  margin: 3rem 0 0;
  padding: 0 3rem;
  text-align: center;
}
@media (min-width: 768px) {
  .media-carousel__text {
    width: 768px;
    margin: 3rem auto 0;
  }
}
@media (min-width: 1280px) {
  .media-carousel__text {
    padding: 0 6rem;
    margin: 6rem auto 0;
  }
}
.media-carousel__text .btn, .media-carousel__text .tml-links a, .tml-links .media-carousel__text a {
  margin: 3rem auto 0;
}
@media (min-width: 1280px) {
  .media-carousel__text .btn, .media-carousel__text .tml-links a, .tml-links .media-carousel__text a {
    margin: 6rem auto 0;
  }
}

.text-img {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
}
@media (min-width: 650px) {
  .text-img {
    flex-direction: row;
  }
}
@media (min-width: 1280px) {
  .text-img {
    gap: 0;
  }
}

.text-img + .text-img {
  margin-top: 6rem;
}
@media (min-width: 1280px) {
  .text-img + .text-img {
    margin-top: 12rem;
  }
}

.text-img__img-outer {
  position: relative;
}
@media (min-width: 650px) {
  .text-img__img-outer {
    flex: 1;
  }
}

.no-flexbox-gap .text-img__img-outer {
  margin: 0 0 6rem;
}

.text-img__img {
  z-index: 1;
  display: block;
  width: 100%;
  height: auto;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .text-img__img {
    flex-shrink: 0;
  }
}

.text-img__img__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding: 3rem 2rem 2rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
}

.text-img__text-outer {
  padding: 0 3rem;
}
@media (min-width: 650px) {
  .text-img__text-outer {
    padding: 0;
    flex: 1;
  }
}

@media (min-width: 1280px) {
  .text-img__text {
    width: 640px;
    max-width: 100%;
  }
}

.text-img__logo {
  display: block;
  width: 7.5rem;
  height: auto;
  margin: 0 0 3rem;
}
@media (min-width: 768px) {
  .text-img__logo {
    width: 10rem;
  }
}

@media (min-width: 650px) {
  .text-img--default .text-img__text {
    padding: 3rem 3rem 0;
  }
}
@media (min-width: 1280px) {
  .text-img--default .text-img__text {
    padding: 0.5rem 6rem 0 12rem;
  }
}

@media (min-width: 650px) {
  .text-img--alt .text-img__text-outer {
    order: 1;
    display: flex;
    justify-content: flex-end;
  }
}
@media (min-width: 650px) {
  .text-img--alt .text-img__text {
    padding: 3rem 3rem 0;
  }
}
@media (min-width: 1280px) {
  .text-img--alt .text-img__text {
    padding: 0.5rem 12rem 0 6rem;
  }
}
@media (min-width: 650px) {
  .text-img--alt .text-img__img-outer {
    order: 2;
  }
}

.text-img--black .std-content h2, .text-img--black .std-content h3, .text-img--black .std-content h4, .text-img--black .std-content h5, .text-img--black .std-content h6, .text-img--black .std-content p, .text-img--black .std-content ul {
  color: white;
}
.text-img--black .std-content a {
  color: white;
}

@media (min-width: 650px) {
  .events-promo .text-img__img-outer {
    height: 100%;
  }
}
@media (min-width: 650px) {
  .events-promo .text-img__img {
    height: 100%;
    object-fit: cover;
  }
}

.article-header--black-white {
  background-color: #000000;
  color: white;
}
.article-header--black-white .date-stamp {
  color: #000000;
  background-color: white;
}

.article-header--green-dark-green {
  background-color: #00e493;
  color: #03343f;
}
.article-header--green-dark-green .date-stamp {
  color: #00e493;
  background-color: #03343f;
}

.article-header--dark-green-green {
  background-color: #03343f;
  color: #00e493;
}
.article-header--dark-green-green .date-stamp {
  color: #03343f;
  background-color: #00e493;
}

.article-header--purple-sky-blue {
  background-color: #7122e5;
  color: #9df6f9;
}
.article-header--purple-sky-blue .date-stamp {
  color: #7122e5;
  background-color: #9df6f9;
}

.article-header--sky-blue-purple {
  background-color: #9df6f9;
  color: #7122e5;
}
.article-header--sky-blue-purple .date-stamp {
  color: #9df6f9;
  background-color: #7122e5;
}

.article-header--red-pink {
  background-color: #e82d5e;
  color: #f9a2c5;
}
.article-header--red-pink .date-stamp {
  color: #e82d5e;
  background-color: #f9a2c5;
}

.article-header--pink-red {
  background-color: #f9a2c5;
  color: #e82d5e;
}
.article-header--pink-red .date-stamp {
  color: #f9a2c5;
  background-color: #e82d5e;
}

.article-header--yellow-blue {
  background-color: #f9e153;
  color: #4d7fff;
}
.article-header--yellow-blue .date-stamp {
  color: #f9e153;
  background-color: #4d7fff;
}

.article-header--blue-yellow {
  background-color: #4d7fff;
  color: #f9e153;
}
.article-header--blue-yellow .date-stamp {
  color: #4d7fff;
  background-color: #f9e153;
}

.article-header--orange-cream {
  background-color: #ee7645;
  color: #ecdec5;
}
.article-header--orange-cream .date-stamp {
  color: #ee7645;
  background-color: #ecdec5;
}

.article-header--cream-orange {
  background-color: #ecdec5;
  color: #ee7645;
}
.article-header--cream-orange .date-stamp {
  color: #ecdec5;
  background-color: #ee7645;
}

.cta-banner {
  padding: 6rem 0;
  text-align: center;
  background-image: url("img/bg-gradient.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #00e493;
  animation: cta-gradient 10s infinite;
}
@media (min-width: 1280px) {
  .cta-banner {
    padding: 12rem 0;
  }
}

@keyframes cta-gradient {
  50% {
    background-image: url("img/bg-gradient-alt.jpg");
  }
}
.team-grid {
  list-style-type: none;
}
@media (min-width: 650px) {
  .team-grid {
    display: flex;
    flex-wrap: wrap;
    margin: -1.5rem;
  }
}

.team-grid__item {
  position: relative;
}
@media (min-width: 650px) {
  .team-grid__item {
    width: 50%;
    padding: 1.5rem;
  }
}
@media (min-width: 1024px) {
  .team-grid__item {
    width: 33.333%;
  }
}

.team-grid__item + .team-grid__item {
  margin: 2rem 0 0;
}
@media (min-width: 650px) {
  .team-grid__item + .team-grid__item {
    margin: 0;
  }
}

.team-grid__item__link {
  position: relative;
  display: block;
}

.team-grid__item__img-outer {
  position: relative;
  z-index: 1;
  padding-bottom: 100%;
}
@media (min-width: 1024px) {
  .team-grid__item__img-outer {
    padding-bottom: 75%;
  }
}

.team-grid__item__img {
  position: absolute;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-grid__item__info {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.team-grid__item__info:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, black 100%);
  opacity: 0.5;
  transition: opacity 0.25s;
}

.team-grid__item__link:hover .team-grid__item__info:before {
  opacity: 0.75;
}

.team-grid__item__text {
  position: absolute;
  top: 60%;
  left: 0;
  z-index: 2;
  width: 100%;
  padding: 0 2rem;
}

.vacancies {
  padding: 3rem 0;
  background-color: #F5F5F5;
}
@media (min-width: 768px) {
  .vacancies {
    padding: 6rem 0;
  }
}
@media (min-width: 1280px) {
  .vacancies {
    padding: 12rem 0;
  }
}

.vacancy-locations {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
  list-style-type: none;
}
@media (min-width: 650px) {
  .vacancy-locations {
    flex-direction: row;
  }
}

@media (min-width: 650px) {
  .vacancy-location {
    width: calc(50% - 3rem);
  }
}
@media (min-width: 768px) {
  .vacancy-location {
    width: calc(33.333% - 3rem);
  }
}

.no-flexbox-gap .vacancy-location {
  margin: 2rem;
}

.vacancy-location__link {
  position: relative;
  display: block;
  text-decoration: none;
  background-color: #000000;
}
@media (min-width: 650px) {
  .vacancy-location__link {
    padding-bottom: 100%;
  }
}
.vacancy-location__link:hover .vacancy-location__link__label:before {
  opacity: 0.5;
}

.vacancy-location__link__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
}

.vacancy-location__link__label {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 3rem;
}
@media (min-width: 650px) {
  .vacancy-location__link__label {
    position: absolute;
  }
}
.vacancy-location__link__label:before {
  content: "";
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: linear-gradient(60deg, #bdf9fd 0%, #00e392 17%, #15a1ac 74%, #1a5684 100%);
  transition: opacity 0.25s;
}
.vacancy-location__link__label .sub-title {
  position: relative;
  z-index: 4;
}

.vacancy-list__outer {
  padding: 3rem;
  background-color: white;
}
@media (min-width: 1280px) {
  .vacancy-list__outer {
    padding: 6rem;
  }
}

.vacancy-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  list-style-type: none;
}

.no-flexbox-gap .vacancy-list__item + .vacancy-list__item {
  margin: 2rem 0 0;
}

.contact-group + .contact-group {
  margin: 6rem 0 0;
}

.global-enqs-list__item + .global-enqs-list__item {
  margin: 3rem 0 0;
}

.hbspt-form input[type=text],
.hbspt-form input[type=email],
.hbspt-form input[type=tel],
.hbspt-form input[type=password],
.hbspt-form textarea,
.hbspt-form select,
.contact-form input[type=text],
.contact-form input[type=email],
.contact-form input[type=tel],
.contact-form input[type=password],
.contact-form textarea,
.contact-form select {
  display: block;
  width: 100%;
  border: 2px solid #000000;
  -webkit-border-radius: 0;
  font-size: 1.8rem;
}
.hbspt-form input[type=text]:hover,
.hbspt-form input[type=email]:hover,
.hbspt-form input[type=tel]:hover,
.hbspt-form input[type=password]:hover,
.hbspt-form textarea:hover,
.hbspt-form select:hover,
.contact-form input[type=text]:hover,
.contact-form input[type=email]:hover,
.contact-form input[type=tel]:hover,
.contact-form input[type=password]:hover,
.contact-form textarea:hover,
.contact-form select:hover {
  border-color: #999999;
}
.hbspt-form input[type=text]:focus,
.hbspt-form input[type=email]:focus,
.hbspt-form input[type=tel]:focus,
.hbspt-form input[type=password]:focus,
.hbspt-form textarea:focus,
.hbspt-form select:focus,
.contact-form input[type=text]:focus,
.contact-form input[type=email]:focus,
.contact-form input[type=tel]:focus,
.contact-form input[type=password]:focus,
.contact-form textarea:focus,
.contact-form select:focus {
  outline: none;
  border-color: #00e493;
}
.hbspt-form input[type=text].error,
.hbspt-form input[type=email].error,
.hbspt-form input[type=tel].error,
.hbspt-form input[type=password].error,
.hbspt-form textarea.error,
.hbspt-form select.error,
.contact-form input[type=text].error,
.contact-form input[type=email].error,
.contact-form input[type=tel].error,
.contact-form input[type=password].error,
.contact-form textarea.error,
.contact-form select.error {
  border-color: red;
}
.hbspt-form input[type=text]::-webkit-input-placeholder,
.hbspt-form input[type=email]::-webkit-input-placeholder,
.hbspt-form input[type=tel]::-webkit-input-placeholder,
.hbspt-form input[type=password]::-webkit-input-placeholder,
.hbspt-form textarea::-webkit-input-placeholder,
.hbspt-form select::-webkit-input-placeholder,
.contact-form input[type=text]::-webkit-input-placeholder,
.contact-form input[type=email]::-webkit-input-placeholder,
.contact-form input[type=tel]::-webkit-input-placeholder,
.contact-form input[type=password]::-webkit-input-placeholder,
.contact-form textarea::-webkit-input-placeholder,
.contact-form select::-webkit-input-placeholder {
  color: #999999;
}
.hbspt-form input[type=text]:-moz-placeholder,
.hbspt-form input[type=email]:-moz-placeholder,
.hbspt-form input[type=tel]:-moz-placeholder,
.hbspt-form input[type=password]:-moz-placeholder,
.hbspt-form textarea:-moz-placeholder,
.hbspt-form select:-moz-placeholder,
.contact-form input[type=text]:-moz-placeholder,
.contact-form input[type=email]:-moz-placeholder,
.contact-form input[type=tel]:-moz-placeholder,
.contact-form input[type=password]:-moz-placeholder,
.contact-form textarea:-moz-placeholder,
.contact-form select:-moz-placeholder {
  color: #999999;
}
.hbspt-form input[type=text]::-moz-placeholder,
.hbspt-form input[type=email]::-moz-placeholder,
.hbspt-form input[type=tel]::-moz-placeholder,
.hbspt-form input[type=password]::-moz-placeholder,
.hbspt-form textarea::-moz-placeholder,
.hbspt-form select::-moz-placeholder,
.contact-form input[type=text]::-moz-placeholder,
.contact-form input[type=email]::-moz-placeholder,
.contact-form input[type=tel]::-moz-placeholder,
.contact-form input[type=password]::-moz-placeholder,
.contact-form textarea::-moz-placeholder,
.contact-form select::-moz-placeholder {
  color: #999999;
}
.hbspt-form input[type=text]:-ms-input-placeholder,
.hbspt-form input[type=email]:-ms-input-placeholder,
.hbspt-form input[type=tel]:-ms-input-placeholder,
.hbspt-form input[type=password]:-ms-input-placeholder,
.hbspt-form textarea:-ms-input-placeholder,
.hbspt-form select:-ms-input-placeholder,
.contact-form input[type=text]:-ms-input-placeholder,
.contact-form input[type=email]:-ms-input-placeholder,
.contact-form input[type=tel]:-ms-input-placeholder,
.contact-form input[type=password]:-ms-input-placeholder,
.contact-form textarea:-ms-input-placeholder,
.contact-form select:-ms-input-placeholder {
  color: #999999;
}
.hbspt-form input[type=text],
.hbspt-form input[type=email],
.hbspt-form input[type=tel],
.hbspt-form input[type=password],
.contact-form input[type=text],
.contact-form input[type=email],
.contact-form input[type=tel],
.contact-form input[type=password] {
  width: 100% !important;
  height: 6rem;
  padding: 0 1rem;
}
.hbspt-form textarea,
.contact-form textarea {
  width: 100% !important;
  height: 20rem;
  padding: 1rem;
}
.hbspt-form select,
.contact-form select {
  width: 100% !important;
  height: 6rem;
  padding: 0 0.5rem;
}

.hs-form > * + * {
  margin: 3rem 0 0;
}

.hbspt-form fieldset {
  max-width: 100% !important;
}
.hbspt-form fieldset > * {
  margin: 3rem 0 0;
}
.hbspt-form fieldset:first-child > *:first-child {
  margin: 0;
}
.hbspt-form .input {
  margin: 0 !important;
}
.hbspt-form .form-columns-1 .hs-form-field,
.hbspt-form .form-columns-2 .hs-form-field {
  width: 100% !important;
}
.hbspt-form .form-columns-2 > * + * {
  margin: 3rem 0 0;
}

.hs-dependent-field > * + * {
  margin: 3rem 0 0;
}

.form-field-label,
.hs-form-field > label {
  display: block;
  margin: 0 0 1rem;
  font-family: "Larsseit-Bold", Arial;
  line-height: 1.75;
}

.hs-field-desc {
  margin: 0 0 1rem;
}

.hs-richtext {
  line-height: 1.75;
}

.hbspt-form .inputs-list {
  list-style-type: none;
}
.hbspt-form .inputs-list li + li {
  margin: 1rem 0 0;
}
.hbspt-form .inputs-list li input {
  margin: 0 1rem 0 0;
}
.hbspt-form .inputs-list li span {
  font-family: "Larsseit-Bold", Arial;
}

.hs_file_upload_field > .input {
  padding: 2rem;
  background-color: #F5F5F5;
}

.legal-consent-container > * + * {
  margin: 3rem 0 0;
}
.legal-consent-container .hs-richtext p {
  font-size: 1.8rem;
  line-height: 1.75;
}

.hs_recaptcha {
  margin: 3rem 0 0 !important;
}

.hs_submit {
  margin: 3rem 0 0;
}

.hs-button, .tml-button {
  display: block;
  padding: 1rem 2rem;
  background-color: #00e493;
  border: none;
  font-family: "BwStretch-Heavy", Impact;
  font-size: 2.7rem;
  text-transform: uppercase;
  color: #000000;
  cursor: pointer;
  -webkit-appearance: none;
}
.hs-button:hover, .tml-button:hover {
  background: #000000;
  color: white;
}

.hs-error-msgs {
  margin: 1.5rem 0 0;
  padding: 2rem;
  background-color: #ffcccc;
}
.hs-error-msgs li {
  font-family: "Larsseit-Bold", Arial;
  color: #000000;
}
.hs-error-msgs li label {
  color: #000000 !important;
}

.hs_error_rollup .hs-error-msgs {
  background-color: red;
}
.hs_error_rollup .hs-error-msgs li label {
  color: white !important;
}

.logo-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  list-style-type: none;
}
@media (min-width: 768px) {
  .logo-grid {
    gap: 6rem;
  }
}

.logo-grid__item {
  width: 7.5rem;
}
@media (min-width: 768px) {
  .logo-grid__item {
    width: 10rem;
  }
}
@media (min-width: 1280px) {
  .logo-grid__item {
    width: 12rem;
  }
}

.no-flexbox-gap .logo-grid__item {
  margin: 1.5rem;
}
@media (min-width: 768px) {
  .no-flexbox-gap .logo-grid__item {
    margin: 2rem;
  }
}

.logo-grid__item__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.accordion {
  list-style-type: none;
}

.accordion__item + .accordion__item {
  margin: 1.5rem 0 0;
}

.accordion__item__toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
  border: none;
  background-color: #F5F5F5;
  color: #000000;
}
.accordion__item__toggle svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
  transform: rotate(90deg);
}
.accordion__item__toggle:hover {
  background-color: #00e493;
}
.accordion__item__toggle.is-active {
  background-color: #000000;
  color: white;
}

.accordion__item__content {
  display: none;
  padding: 3rem;
  border: 2px solid #000000;
}
.accordion__item__content.is-open {
  display: block;
}

.news-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 3rem;
}
.news-nav .title {
  margin: 0 0 3rem;
}
@media (min-width: 650px) {
  .news-nav {
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
  }
}

.news-nav__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  list-style-type: none;
}
@media (min-width: 1280px) {
  .news-nav__list {
    gap: 3rem;
  }
}

.no-flexbox-gap .news-nav__list {
  margin: 0 1.5rem;
}

.news-hero {
  position: relative;
  background-color: #000000;
}
.news-hero .inner {
  position: relative;
  z-index: 2;
}

.news-hero__img-outer {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.news-hero__img {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .news-hero__content {
    max-width: 768px;
  }
}

.news-hero__link {
  text-decoration: none;
}
.news-hero__link svg {
  width: 4rem;
  height: 4rem;
  fill: white;
  transition: fill 0.15s;
}
.news-hero__link:hover svg {
  fill: #00e493;
}

.news-hero__img__caption {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 3;
}

.date-stamp {
  display: inline-block;
  padding: 0.5rem;
  font-family: "BwStretch-Heavy", Impact;
  font-size: 1.5rem;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .date-stamp--lg {
    font-size: 2.1rem;
  }
}

.date-stamp--white {
  background-color: white;
  color: #000000;
}

.date-stamp--black {
  background-color: #000000;
  color: white;
}

@media (min-width: 1280px) {
  .archive-feed-col {
    flex: 1;
  }
}

.archive-feed {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 3rem;
  list-style-type: none;
}
@media (min-width: 650px) {
  .archive-feed {
    flex-direction: row;
  }
}
@media (min-width: 1280px) {
  .archive-feed {
    gap: 6rem;
  }
}

.no-flexbox-gap .archive-feed__item {
  margin: 3rem;
}

.archive-feed__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}
@media (min-width: 650px) {
  .archive-feed__item {
    width: calc(50% - 3rem);
  }
}
@media (min-width: 768px) {
  .archive-feed__item {
    width: calc(33.333% - 3rem);
  }
}
@media (min-width: 1280px) {
  .archive-feed__item {
    width: calc(33.333% - 6rem);
  }
}
@media (min-width: 1280px) {
  .archive-feed__item {
    flex-direction: row;
  }
}

.archive-feed__item__link {
  color: #000000;
  line-height: 1.75;
}
.archive-feed__item__link:hover {
  color: #00e493;
}

.archive-feed__item__img-outer {
  display: none;
}
@media (min-width: 768px) {
  .archive-feed__item__img-outer {
    display: block;
    width: 8rem;
    height: 8rem;
    flex-shrink: 0;
  }
}

@media (min-width: 768px) {
  .no-flexbox-gap .archive-feed__item__img-outer {
    margin: 0 0 2rem;
  }
}

.archive-feed__item__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.archive-feed__item__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.author {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}

.author__img-outer {
  width: 5rem;
  height: 5rem;
  flex-shrink: 0;
}

.no-flexbox-gap .author__img-outer {
  margin: 0 2rem 0 0;
}

.author__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.author__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.content-hero {
  position: relative;
  background-color: #000000;
}
@media screen and (orientation: portrait) {
  .content-hero {
    height: 75vh;
  }
}
@media screen and (orientation: landscape) {
  .content-hero {
    height: 100vh;
  }
}

.content-hero__img-outer {
  position: relative;
  z-index: 1;
  height: 100%;
  background-color: #000000;
}

.content-hero.has-overlay .content-hero__img-outer:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}

.content-hero__img {
  opacity: 0.7;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-hero__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  width: 100%;
}

.content-hero__img-caption {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 4;
}

.home-hero {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #000000;
}
@media screen and (orientation: portrait) {
  .home-hero {
    height: 75vh;
  }
}
@media screen and (orientation: landscape) {
  .home-hero {
    height: 100vh;
  }
}
@media (min-width: 1024px) {
  .home-hero {
    perspective: 200px;
  }
}

@media (min-width: 1024px) {
  .is-safari .home-hero {
    perspective: 1px;
  }
}

.home-hero__layer-1 {
  position: absolute;
  top: -10%;
  left: -10%;
  z-index: 1;
  width: 120%;
  height: 120%;
  filter: blur(0.5rem);
  opacity: 0.75;
}
.home-hero__layer-1 img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.home-hero__layer-2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
}

.home-hero__layer-2__img,
.home-hero__layer-4__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
@media (min-width: 1024px) {
  .home-hero__layer-2__img,
.home-hero__layer-4__img {
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    transform: skew(-35deg);
    overflow: hidden;
  }
}
.home-hero__layer-2__img img,
.home-hero__layer-4__img img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media (min-width: 1024px) {
  .home-hero__layer-2__img img,
.home-hero__layer-4__img img {
    transform: skew(35deg);
  }
}

@media (min-width: 1024px) {
  .home-hero__layer-2__img {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  }
}

.home-hero__layer-3 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.home-hero__layer-3 svg {
  width: 66.667%;
  fill: white;
}
@media (min-width: 768px) {
  .home-hero__layer-3 svg {
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .home-hero__layer-3 svg {
    width: 33.333%;
  }
}

.home-hero__layer-4 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
}

.home-hero__layer-5 {
  position: absolute;
  bottom: 3rem;
  z-index: 5;
  width: 100%;
}
@media (min-width: 1280px) {
  .home-hero__layer-5 {
    bottom: 6rem;
  }
}

.home-hero__content__title {
  font-size: 2.1rem;
  line-height: 1.75;
  color: white;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.75);
}
@media (min-width: 1280px) {
  .home-hero__content__title {
    font-size: 2.7rem;
  }
}
.home-hero__content__title strong {
  font-family: "Larsseit-Bold", Arial;
}

.home-hero__img-caption {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 6;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.75);
}

.cs-hero {
  position: relative;
  background-color: #000000;
}
@media screen and (orientation: portrait) {
  .cs-hero {
    height: 75vh;
  }
}
@media screen and (orientation: landscape) {
  .cs-hero {
    height: 100vh;
  }
}

.cs-hero__img-outer {
  position: relative;
  z-index: 1;
  height: 100%;
}
.cs-hero__img-outer:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 50%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, black 100%);
}

.cs-hero__img {
  position: relative;
  z-index: 2;
  opacity: 0.7;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cs-hero__content-outer {
  position: absolute;
  bottom: 3rem;
  z-index: 2;
  width: 100%;
}
@media (min-width: 1280px) {
  .cs-hero__content-outer {
    bottom: 6rem;
  }
}

@media (min-width: 768px) {
  .cs-hero__content {
    width: 75%;
  }
}
@media (min-width: 1280px) {
  .cs-hero__content {
    width: 66.667%;
  }
}
.cs-hero__content .supporting-text {
  display: none;
}
@media (min-width: 768px) {
  .cs-hero__content .supporting-text {
    display: block;
  }
}

.cs-hero__content__logo {
  display: block;
  width: 7.5rem;
  height: auto;
  margin: 0 0 3rem;
}
@media (min-width: 768px) {
  .cs-hero__content__logo {
    width: 10rem;
  }
}
@media (min-width: 1280px) {
  .cs-hero__content__logo {
    margin: 0 0 6rem;
  }
}

.cs-hero__arrow {
  display: block;
  width: 4rem;
  height: 4rem;
  margin: 3rem 0 0;
}
@media (min-width: 768px) {
  .cs-hero__arrow {
    position: absolute;
    bottom: 0;
    right: 3rem;
    z-index: 1;
    margin: 0;
  }
}
@media (min-width: 1280px) {
  .cs-hero__arrow {
    right: 6rem;
  }
}
.cs-hero__arrow svg {
  width: 4rem;
  fill: white;
  transition: fill 0.15s;
}
.cs-hero__arrow:hover svg {
  fill: #00e493;
}

.cs-hero__img-caption {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 5;
}

.hero-content {
  text-align: center;
}
@media (min-width: 768px) {
  .hero-content .supporting-text {
    padding: 0 25%;
  }
}

.services-outer {
  background-color: #000000;
}
@media (min-width: 768px) {
  .services-outer {
    position: relative;
    height: 75vh;
  }
}

@media (min-width: 768px) {
  .services-scroller {
    position: relative;
    display: flex;
    height: 100%;
  }
}

.services-list-outer {
  padding: 6rem 3rem;
}
@media (min-width: 768px) {
  .services-list-outer {
    position: relative;
    z-index: 2;
    padding: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    scroll-snap-type: y proximity;
    -ms-overflow-style: none;
  }
  .services-list-outer::-webkit-scrollbar {
    display: none;
  }
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}
@media (min-width: 768px) {
  .services-list {
    display: block;
    width: 50%;
    padding: 22.5vh 0;
  }
}
@media (min-width: 1024px) {
  .services-list {
    padding: 17.5vh 0;
  }
}

.services-list__item {
  width: 30rem;
  padding: 3rem;
  background-color: rgba(255, 255, 255, 0.05);
}
@media (min-width: 650px) {
  .services-list__item {
    width: calc(50% - 3rem);
  }
}
@media (min-width: 768px) {
  .services-list__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 30vh;
    padding: 0 3rem;
    scroll-snap-align: center;
    background: none;
  }
}
@media (min-width: 1024px) {
  .services-list__item {
    height: 40vh;
  }
}
@media (min-width: 1280px) {
  .services-list__item {
    padding: 0 6rem;
  }
}

.no-flexbox-gap .services-list__item {
  margin: 2rem;
}

.services-list__item__link {
  display: block;
  text-decoration: none;
  text-align: center;
}
@media (min-width: 768px) {
  .services-list__item__link {
    padding: 0 3rem 0 0;
    text-align: left;
  }
}
@media (min-width: 1280px) {
  .services-list__item__link {
    width: 66.667%;
    padding: 0;
  }
}
@media (min-width: 1600px) {
  .services-list__item__link {
    width: 50%;
  }
}
.services-list__item__link h2 {
  margin: 0 0 1.5rem;
  font-family: "BwStretch-Heavy", Impact;
  font-size: 2.5rem;
  line-height: 1;
  text-transform: uppercase;
  background: linear-gradient(60deg, #bdf9fd 0%, #00e392 17%, #15a1ac 74%, #1a5684 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (min-width: 768px) {
  .services-list__item__link h2 {
    font-size: 5rem;
    filter: grayscale(100%);
    opacity: 0.25;
  }
}
@media (min-width: 1280px) {
  .services-list__item__link h2 {
    font-size: 7.5rem;
  }
}
.services-list__item__link p {
  color: white;
  line-height: 1.75;
}
@media (min-width: 768px) {
  .services-list__item__link p {
    opacity: 0.25;
  }
}
.services-list__item__link:hover h2 {
  background: white;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 768px) {
  .services-list__item.is-highlighted .services-list__item__link h2 {
    filter: grayscale(0%);
    opacity: 1;
  }
}
@media (min-width: 768px) {
  .services-list__item.is-highlighted .services-list__item__link p {
    opacity: 1;
  }
}

.services-imgs {
  display: none;
  position: relative;
}
@media (min-width: 768px) {
  .services-imgs {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: block;
    width: 50%;
    height: 100%;
  }
}

@media (min-width: 768px) {
  .services-img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s;
  }
}
.services-img img {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.services-img.is-showing {
  opacity: 1;
}

.services-img__img-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding: 3rem 2rem 2rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
}

@media (min-width: 1024px) {
  .investor-nav__toggle {
    display: none;
  }
}

.investor-nav__list {
  display: none;
  padding: 3rem;
  border: 2px solid #000000;
}
.investor-nav__list.is-open {
  display: block;
}
@media (min-width: 1024px) {
  .investor-nav__list {
    display: block !important;
    padding: 0;
    border: none;
  }
}

.investor-content {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  margin: 0 0 6rem;
}
@media (min-width: 1024px) {
  .investor-content {
    flex-direction: row;
    gap: 3rem;
  }
}
@media (min-width: 1280px) {
  .investor-content {
    margin: 0 0 12rem;
  }
}

@media (min-width: 1024px) {
  .investor-nav {
    width: calc(25% - 3rem);
    flex-shrink: 0;
  }
}

@media (min-width: 1024px) {
  .investor-content-sections {
    flex: 1;
  }
}

.no-flexbox-gap .investor-content-sections {
  margin: 6rem 0 0;
}

.analysts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  grid-gap: 2rem;
  margin: 0 auto;
  list-style-type: none;
}

.analysts-grid__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  border: 2px solid #F5F5F5;
}

.analysts-grid__item__img-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  margin: 0 0 3rem;
}

.analysts-grid__item__img {
  display: inline-block;
  width: 10rem;
  object-fit: contain;
  object-position: center;
}

.video-grid {
  list-style-type: none;
}

.video-grid__item + .video-grid__item {
  margin: 3rem 0 0;
}

.brand-intro-outer {
  position: relative;
  background-color: #000000;
  overflow: hidden;
}
.brand-intro-outer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #999999 0%, rgba(153, 153, 153, 0.33) 10%, rgba(153, 153, 153, 0) 20%);
  transform: skew(-35deg);
  opacity: 0.15;
}
.brand-intro-outer:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 50%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, black 100%);
}
.brand-intro-outer .inner {
  position: relative;
  z-index: 3;
}

@media (min-width: 1024px) {
  .brand-intro {
    display: flex;
    gap: 6rem;
  }
}

.brand-intro__headline {
  margin: 0 0 3rem;
}
@media (min-width: 1024px) {
  .brand-intro__headline {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    margin: 0;
  }
}
.brand-intro__headline .title {
  margin: 0 0 3rem;
  text-align: center;
}
@media (min-width: 768px) {
  .brand-intro__headline .title {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .brand-intro__headline .logo-grid {
    justify-content: flex-start;
  }
}

@media (min-width: 1024px) {
  .no-flexbox-gap .brand-intro__headline {
    margin: 0 0 6rem;
  }
}

.brand-intro__title {
  margin: 0 auto 3rem;
  font-family: "BwStretch-Heavy", Impact;
  font-size: 5rem;
  text-transform: uppercase;
  line-height: 1;
  color: white;
  text-align: center;
}
@media (min-width: 650px) {
  .brand-intro__title {
    width: 9ch;
  }
}
@media (min-width: 768px) {
  .brand-intro__title {
    margin: 0 0 6rem;
    font-size: 15rem;
    text-align: left;
  }
}
@media (min-width: 1280px) {
  .brand-intro__title {
    margin: 0 0 12rem;
    letter-spacing: -0.2rem;
  }
}

.brand-intro__text {
  text-align: center;
}
@media (min-width: 768px) {
  .brand-intro__text {
    width: 75%;
    margin: 0 0 0 25%;
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .brand-intro__text {
    width: calc(40% - 6rem);
    margin: 16rem 0 0;
  }
}
.brand-intro__text .std-content {
  color: white;
}

.newsletter-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  grid-gap: 2rem;
  list-style-type: none;
}

.newsletter-grid__item__img-outer {
  position: relative;
  padding-bottom: 56.75%;
  margin: 0 0 2rem;
}

.newsletter-grid__item__img {
  position: absolute;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tml-field-wrap + .tml-field-wrap,
.form-row + .form-row {
  margin: 3rem 0 0;
}

.tml-field-wrap + .form-row {
  margin: 3rem 0 0;
}

.tml-label {
  display: block;
  margin: 0 0 1rem;
  font-family: "Larsseit-Bold", Arial;
  line-height: 1.75;
}

.tml-rememberme-wrap {
  margin: 3rem 0 0;
}
.tml-rememberme-wrap label {
  display: inline;
  margin: 0;
}

.tml-rememberme-wrap + .form-row {
  margin: 3rem 0 0;
}

.tml-login .tml-alerts,
.tml-lostpassword .tml-alerts {
  margin: 0 0 3rem;
}

.tml-errors {
  padding: 2rem;
  list-style-type: none;
  background-color: #e82d5e;
}

.tml-error {
  font-family: "Larsseit-Bold", Arial;
  color: white;
}

.tml-error + .tml-error {
  margin: 1rem 0 0;
}

.tml-links {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 3rem 0 0;
  list-style-type: none;
}
.no-flexbox-gap .tml-links li + .tml-links li {
  margin: 2rem 0 0;
}

.tml-messages {
  padding: 2rem;
  list-style-type: none;
  background-color: #00e493;
}

.tml-message {
  font-family: "Larsseit-Bold", Arial;
  color: white;
}
.tml-message a {
  color: inherit;
}

.tml-message + .tml-message {
  margin: 1rem 0 0;
}

.tml-register-link {
  display: none;
}

.cookies-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  padding: 3rem;
  text-align: center;
  background-color: white;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);
}

.responsive-table {
  position: relative;
  width: 100%;
}

.responsive-table table {
  border-collapse: collapse;
  border-spacing: 0px;
  background-color: #F5F5F5;
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
.responsive-table table tr:not(:last-child) td {
  border-bottom: 1px solid white;
}
.responsive-table table th {
  border-bottom: 3px solid #000000;
  font-family: "Larsseit-Bold", Arial;
  color: #000000;
}
.responsive-table table th, .responsive-table table td {
  padding: 1rem;
  font-size: 1.5rem;
  line-height: 1.75;
  vertical-align: top;
  text-align: left;
}

.responsive-table__helper {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0 0 1rem;
  font-family: "Larsseit-Bold", Arial;
  font-size: 1.2rem;
  background-color: #00e493;
}

.nav-links {
  display: flex;
  justify-content: center;
  margin: 6rem 0 0;
}
.nav-links > * + * {
  display: block;
  margin: 0 1rem;
}
.nav-links a {
  font-family: "Larsseit-Bold", Arial;
  color: #000000;
  text-decoration: none;
}
.nav-links a:hover {
  color: #00e493;
}
.nav-links .current {
  font-family: "Larsseit-Bold", Arial;
  color: #00e493;
}

.file-group + .file-group {
  border-top: 1px solid white;
  padding: 6rem 0 0;
  margin: 6rem 0 0;
}

.file-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  list-style-type: none;
}

.file-grid__item {
  position: relative;
  width: 20rem;
}

.file-grid__item__link {
  display: block;
  text-align: center;
  color: inherit;
}
.file-grid__item__link:hover {
  color: #00e493;
}

.file-grid__item__img-outer {
  position: relative;
  z-index: 1;
  padding-bottom: 56.75%;
  margin: 0 0 1rem;
  background-color: white;
}

.file-grid__item__img {
  position: absolute;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-grid__item__label {
  font-size: 1.5rem;
  color: inherit;
}

.no-flexbox-gap .file-grid {
  margin: -2rem;
}
.no-flexbox-gap .file-grid__item {
  padding: 2rem;
}