.vacancies {
  padding: $padding-md 0;

  background-color: $color-grey-light;

    @include mq(screen-sm) {
      padding: $padding-lg 0;
    }

    @include mq(screen-lg) {
      padding: $padding-xl 0;
    }

}

.vacancy-locations {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: $padding-sm;

  list-style-type: none;

    @include mq(screen-xs) {
      flex-direction: row;
    }

}

.vacancy-location {
  
  @include mq(screen-xs) {
    width: calc(50% - 3rem);
  }

  @include mq(screen-sm) {
    width: calc(33.333% - 3rem);
  }

}

.no-flexbox-gap {
  
  .vacancy-location {
    margin: $padding-sm;
  }

}

.vacancy-location__link {
  position: relative;
  display: block;

  text-decoration: none;

  background-color: $color-black;

    @include mq(screen-xs) {
      padding-bottom: 100%;
    }

    &:hover {
      
      .vacancy-location__link__label:before {
        opacity: 0.5;
      }

    }

}

.vacancy-location__link__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;
  object-fit: cover;

  opacity: $img-fade;
}

.vacancy-location__link__label {
  position: relative;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  padding: $padding-md;

    @include mq(screen-xs) {
      position: absolute;
    }

    &:before {
      content: "";

      position: absolute;
      z-index: 3;

      width: 100%;
      height: 100%;

      opacity: 0;
      background: $grad-green;

      transition: opacity $animation-speed-md;
    }

    .sub-title {
      position: relative;
      z-index: 4;
    }

}

.vacancy-list__outer {
  padding: $padding-md;

  background-color: white;

    @include mq(screen-lg) {
      padding: $padding-lg;
    }

}

.vacancy-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $padding-sm;

  list-style-type: none;
}

.no-flexbox-gap {
  
  .vacancy-list__item + .vacancy-list__item {
    margin: $padding-sm 0 0;
  }

}

// Contact info

.contact-group + .contact-group {
  margin: $padding-lg 0 0;
}

.global-enqs-list__item + .global-enqs-list__item {
  margin: $padding-md 0 0;
}