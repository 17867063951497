// Buttons

.btn {
  position: relative;

  display: inline-block;
  padding: 0 0 0.5rem;

  font-family: $larsseit-bold;
  font-size: $font-size-sm;
  text-decoration: none;

  overflow: hidden;
  border: none;
  background: none;
  cursor: pointer;

    &:before {
      content: "";

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 2px;
    }

    &:after {
      content: "";

      position: absolute;
      bottom: 0;
      right: 100%;
      z-index: 2;

      width: 100%;
      height: 2px;

      transition: right $animation-speed-sm;
    }

    &:hover {
      
      &:after {
        right: 0;
      }

    }

}

.btn--white {
  color: white;

    &:before {
      background-color: $color-green;
    }

    &:after {
      background-color: white;
    }

}

.btn--black {
  color: $color-black;

    &:before {
      background-color: $color-green;
    }

    &:after {
      background-color: $color-black;
    }

}

// Button list

.btn-list {
  list-style-type: none;
}

.btn-list__item + .btn-list__item {
  margin: $padding-sm 0 0;
}

.btn-list__item--current {
  
  .btn:after {
    left: 0;
  }

}

// Button group

.btn-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  list-style-type: none;
}

.btn-group__item {
  padding: 1rem;
}