.tml-field-wrap + .tml-field-wrap,
.form-row + .form-row {
  margin: $padding-md 0 0;
}

.tml-field-wrap + .form-row {
  margin: $padding-md 0 0;
}

.tml-label {
  display: block;
  margin: 0 0 1rem;

  font-family: $larsseit-bold;
  line-height: $line-height-md;
}


.tml-rememberme-wrap {
  margin: $padding-md 0 0;

    label {
      display: inline;
      margin: 0;
    }

}

.tml-rememberme-wrap + .form-row {
  margin: $padding-md 0 0;
}

.tml-button {
  @extend .hs-button;  
}

.tml-login .tml-alerts,
.tml-lostpassword .tml-alerts {
  margin: 0 0 $padding-md;
}

.tml-errors {
  padding: $padding-sm;
  list-style-type: none;

  background-color: $color-red;
}

.tml-error {
  font-family: $larsseit-bold;
  color: white;
}

.tml-error + .tml-error {
  margin: 1rem 0 0;
}

.tml-links {
  display: flex;
  flex-wrap: wrap;
  gap: $padding-sm;
  margin: $padding-md 0 0;

  list-style-type: none;

    a {
      @extend .btn;
      @extend .btn--black;
    }

}

.no-flexbox-gap {

  .tml-links li + .tml-links li {
    margin: $padding-sm 0 0;
  }

}

.tml-messages {
  padding: $padding-sm;
  list-style-type: none;

  background-color: $color-green;
}

.tml-message {
  font-family: $larsseit-bold;
  color: white;

    a {
      color: inherit;
    }

}

.tml-message + .tml-message {
  margin: 1rem 0 0;
}

.tml-register-link {
  display: none;
}
