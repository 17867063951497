// =================================================
// SPACING STYLES
// =================================================

// Margin top

.mt-xs {
  margin-top: 0.5rem;

    @include mq(screen-sm) {
      margin-top: 1rem;
    }

}

.mt-sm {
  margin-top: 1rem;

    @include mq(screen-sm) {
      margin-top: $padding-sm;
    }

}

.mt-md {
  margin-top: $padding-sm;

    @include mq(screen-sm) {
      margin-top: $padding-md;
    }

}

.mt-lg {
  margin-top: $padding-md;

    @include mq(screen-sm) {
      margin-top: $padding-lg;
    }

}

.mt-xl {
  margin-top: $padding-lg;

    @include mq(screen-sm) {
      margin-top: $padding-xl;
    }
    
}

// Margin bottom

.mb-xs {
  margin-bottom: 0.5rem;

    @include mq(screen-sm) {
      margin-bottom: 1rem;
    }

}

.mb-sm {
  margin-bottom: 1rem;

    @include mq(screen-sm) {
      margin-bottom: $padding-sm;
    }

}

.mb-md {
  margin-bottom: $padding-sm;

    @include mq(screen-sm) {
      margin-bottom: $padding-md;
    }

}

.mb-lg {
  margin-bottom: $padding-md;

    @include mq(screen-sm) {
      margin-bottom: $padding-lg;
    }

}

.mb-xl {
  margin-bottom: $padding-lg;

    @include mq(screen-sm) {
      margin-bottom: $padding-xl;
    }
    
}

// Margin

.mtb-xs {
  margin: 0.5rem 0;

    @include mq(screen-sm) {
      margin: 1rem 0;
    }

}

.mtb-sm {
  margin: 1rem 0;

    @include mq(screen-sm) {
      margin: $padding-sm 0;
    }

}

.mtb-md {
  margin: $padding-sm 0;

    @include mq(screen-sm) {
      margin: $padding-md 0;
    }

}

.mtb-lg {
  margin: $padding-md 0;

    @include mq(screen-sm) {
      margin: $padding-lg 0;
    }

}

.mtb-xl {
  margin: $padding-lg 0;

    @include mq(screen-sm) {
      margin: $padding-xl 0;
    }
    
}

// Padding top

.pt-xs {
  padding-top: 0.5rem;

    @include mq(screen-sm) {
      padding-top: 1rem;
    }

}

.pt-sm {
  padding-top: 1rem;

    @include mq(screen-sm) {
      padding-top: $padding-sm;
    }

}

.pt-md {
  padding-top: $padding-sm;

    @include mq(screen-sm) {
      padding-top: $padding-md;
    }

}

.pt-lg {
  padding-top: $padding-md;

    @include mq(screen-sm) {
      padding-top: $padding-lg;
    }

}

.pt-xl {
  padding-top: $padding-lg;

    @include mq(screen-sm) {
      padding-top: $padding-xl;
    }
    
}

// Padding bottom

.pb-xs {
  padding-bottom: 0.5rem;

    @include mq(screen-sm) {
      padding-bottom: 1rem;
    }

}

.pb-sm {
  padding-bottom: 1rem;

    @include mq(screen-sm) {
      padding-bottom: $padding-sm;
    }

}

.pb-md {
  padding-bottom: $padding-sm;

    @include mq(screen-sm) {
      padding-bottom: $padding-md;
    }

}

.pb-lg {
  padding-bottom: $padding-md;

    @include mq(screen-sm) {
      padding-bottom: $padding-lg;
    }

}

.pb-xl {
  padding-bottom: $padding-lg;

    @include mq(screen-sm) {
      padding-bottom: $padding-xl;
    }
    
}

// Padding

.ptb-xs {
  padding: 0.5rem 0;

    @include mq(screen-sm) {
      padding: 1rem 0;
    }

}

.ptb-sm {
  padding: 1rem 0;

    @include mq(screen-sm) {
      padding: $padding-sm 0;
    }

}

.ptb-md {
  padding: $padding-sm 0;

    @include mq(screen-sm) {
      padding: $padding-md 0;
    }

}

.ptb-lg {
  padding: $padding-md 0;

    @include mq(screen-sm) {
      padding: $padding-lg 0;
    }

}

.ptb-xl {
  padding: $padding-lg 0;

    @include mq(screen-sm) {
      padding: $padding-xl 0;
    }
    
}