.cookies-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;

  width: 100%;
  padding: $padding-md;

  text-align: center;

  background-color: white;
  box-shadow: 0 -4px 8px rgba(0,0,0,0.25);
}