// =================================================
// HELPER CLASSES
// =================================================

// Text alignment

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

// For print only

.for-print-only {
  display: none;
}

// Hide from IE

.hide-from-ie {
  
  @include ie-11-support {
    display: none;
  }

}

// Responsive videos

.video-embed {
	position: relative;
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;

		iframe,
		object,
		embed {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

}

// Responsive images

.flex-img {
	position: relative;
}

.flex-img img {
	position: relative;
	z-index: 1;

	@include flex-img;
}

.flex-img__img-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  width: 100%;
  padding: $padding-md $padding-sm $padding-sm;

  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
}