// Mobile header

.mob-header,
.mob-nav-outer {
  
  @include mq(screen-md) {
    display: none !important;
    visibility: hidden !important;
  }

}

.mob-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: $padding-md;
}

.mob-header__logo {
  display: block;
  width: 5rem;
  height: 5rem;

  visibility: visible;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.mob-nav-toggle {
  display: flex;
  align-items: center;
  gap: 1rem;

  font-family: $bw-heavy;
  font-size: $font-size-md;
  text-transform: uppercase;

  border: none;
  background: none;

    &.is-active {
      color: $color-green;
    }

    svg {
      width: 3rem;
      height: 3rem;
      fill: currentColor;
    }

}

.no-flexbox-gap {

  .mob-nav-toggle svg {
    margin: 0 1rem 0 0;
  }

}

.mob-nav-outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;

  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 $padding-md;

  background-color: $color-black;

  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;

    &.is-open {
      z-index: 2;

      visibility: visible;
      opacity: 1;
    }

}

.mob-nav__list {

  > li + li {
    margin: 1.5rem 0 0;
  }
  
  > li > a {
    font-family: $bw-heavy;
    font-size: $font-size-md;
    color: white;
    text-decoration: none;
    text-transform: uppercase;

      @include mq(screen-sm) {
        font-size: $font-size-lg;
      }
      
  }

  > li > a.is-active {
    color: $color-green;
  }

  .sub-menu {
    display: none;
    padding: 1.5rem 0 0;
    margin: 0 0 0 2rem;

      &.is-open {
        display: block;
      }

  }

  .sub-menu > li + li {
    margin: 1rem 0 0;
  }
  
  .sub-menu a {
    font-size: $font-size-xs;
    color: white;
    text-decoration: none;
  }

}

// Tablet/Desktop

.pg-header {
  display: none;
  visibility: hidden;

    @include mq(screen-md) {
      position: absolute;
      top: 1rem;
      left: 0;
      z-index: 10000;

      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: $padding-md;

      visibility: visible;
    }

    @include mq(screen-lg) {
      padding: $padding-md $padding-lg;
    }

    .logout {
      position: absolute;
      top: 1rem;
      right: $padding-md;
    }

}

.pg-header__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  z-index: 1;

  display: block;
  width: 7.5rem;
  height: 7.5rem;

    svg {
      transition: all 0.5s ease-in-out;
    }

    &:hover svg {
      fill: $color-green;
    }

}

.main-nav__list {
  display: flex;
  gap: $padding-md;

  list-style-type: none;

    @include mq(screen-lg) {
      gap: $padding-lg;
    }

    a {
      text-decoration: none;
      cursor: pointer;
    }

}

.has-hero .main-nav__list a {
  text-shadow: 0 2px 3px rgba(black, 0.75);
}

.no-flexbox-gap {
  
  .main-nav__list:first-of-type li {
    
    @include mq(screen-md) {
      margin: 0 $padding-md 0 0;
    }

  }

  .main-nav__list:last-of-type li {
    
    @include mq(screen-md) {
      margin: 0 0 0 $padding-md;
    }

  }

}

// Sub navigation

.sub-nav-outer {
  position: absolute;
  top: -100%;
  left: 0;
  z-index: 2;

  width: 100%;

  background-color: $color-black;
  transition: all 0.5s ease-in-out;
  box-shadow: $box-shadow-lg;

  opacity: 0;

    @include mq(screen-md) {
      padding: 15rem 0 $padding-xl;
    }

    @include mq(screen-lg) {
      padding: 20rem 0 $padding-xl;
    }


    &:after {
      content: "";

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 3rem;

      background: $grad-green;
    }

    &.is-open {
      top: 0;

      opacity: 1;

        @media screen and (max-width: 1023px) {
          display: none;
        }

    }

}

.sub-nav-content-outer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  visibility: hidden;
  opacity: 0;

  width: 100%;
  padding: 0 $padding-md;

  transition: all 0.5s ease-out;
  transition-delay: 0.25s;

    &.is-visible {
      visibility: visible;
      opacity: 1;
    }

    @include mq(screen-md) {
      top: 15rem;
    }

    @include mq(screen-lg) {
      top: 20rem;
    }

    @include mq(screen-lg) {
      padding: 0 $padding-lg;
    }

    @include mq(screen-xl) {
      width: $screen-xl;
    }


}

.sub-nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub-nav-content {

    @include mq(screen-md) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

}

.sub-nav-content__text {
  display: none;

    @include mq(screen-md) {
      display: block;
      width: 25%;
    }
    
}

.sub-nav {

  @include mq(screen-md) {
    width: 70%;
  }

  @include mq(screen-lg) {
    width: 66.667%;
  }

  @include mq(screen-xl) {
    width: 50%;
  }

}

.sub-nav__list {
  display: flex;
  flex-wrap: wrap;
  gap: $padding-xs;

  list-style-type: none;

    li {
      width: calc(33.333% - 1.5rem);

      line-height: $line-height-md;
    }

    li.current-item a {
      text-decoration: underline;
    }

    a {
      color: white;
      text-decoration: none;

        &:hover {
          color: $color-green;
        }

    }

    li:first-child a {
      font-family: $larsseit-bold;
      color: $color-green;
    }

    li:first-child a:hover {
      color: white;
    }

}

.sub-nav__list + .close-sub-nav {
  margin: $padding-lg 0 0;
}

.close-sub-nav {
  display: flex;
  align-items: center;
  padding: 0;
  
  border: none;
  background: none;

  color: $color-green;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 0.5rem 0 0;

      fill: currentColor;
    }

    &:hover {
      color: white;
    }

}

// Body states

body.mob-nav-is-open {
  
  .mob-header__logo {
    opacity: 0;
    visibility: hidden;
  }

  li.current_page_item a {
    color: $color-green;
  }

}

body.has-hero {
  
  .main-nav__list {

    a {
      color: white;
    }

    a:hover {
      color: $color-green;
    }

  }

  .pg-header__logo svg {
    fill: white;
  }

  .mob-header__logo svg {
    fill: white;
  }

  .mob-nav-toggle {
    color: white;
  }

}

body.has-hero.mob-nav-is-open {
  
  .mob-nav-toggle {
    color: $color-green;
  }

}


body.no-hero.nav-is-open {
  
  .main-nav__list {

    a {
      color: white;
    }

  }

  .pg-header__logo svg {
    fill: white;
  }

}

body.no-hero.nav-is-open,
body.has-hero.nav-is-open {
  
  .main-nav__list {
    
    .active-menu a {
      color: $color-green;
    }

  }

}

body.no-hero {
  
  .main-nav__list {

    a {
      color: $color-black;
    }

    a:hover {
      color: $color-green;
    }

  }

  .main-content {
    padding: 15rem 0 0;

      @include mq(screen-md) {
        padding: 20rem 0 0;
      }

  }

}
