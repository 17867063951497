// Titles

.title {
  font-family: $bw-heavy;
  text-transform: uppercase;
  line-height: 1;
}

.title a {
  text-decoration: none;
  color: inherit;
}

.title--xl {
  font-size: $title-size-sm;

    @include mq(screen-sm) {
      font-size: $title-size-md;
    }

    @include mq(screen-lg) {
      font-size: $title-size-xl;
      letter-spacing: $kerning-md;
    }

}

.title--lg {
  font-size: $title-size-sm;

    @include mq(screen-sm) {
      font-size: $title-size-md;
    }

    @include mq(screen-lg) {
      font-size: $title-size-lg;
      letter-spacing: $kerning-md;
    }

}

.title--md {
  font-size: $title-size-sm;

    @include mq(screen-sm) {
      font-size: $title-size-md;
    }

}

.title--sm {
  font-size: $title-size-xs;

    @include mq(screen-sm) {
      font-size: $title-size-sm;
    }

}

.title--white {
  color: white;
}

// Sub-titles

.sub-title {
  font-family: $larsseit-bold;
  font-size: $font-size-md;
  line-height: $line-height-sm;
}

a.sub-title {
  display: block;
  text-decoration: none;
}

.sub-title--sm {
  font-size: $font-size-xs;
}

.sub-title--md {
  font-size: $font-size-sm;

    @include mq(screen-lg) {
      font-size: $font-size-md;
    }

}

.sub-title--lg {
  font-size: $font-size-md;

    @include mq(screen-lg) {
      font-size: $font-size-lg;
    }

}

.sub-title--black {
  color: $color-black;
}

a.sub-title--black:hover {
  color: $color-green;
}

.sub-title--white {
  color: white;
}

// Text

.supporting-text {
  font-size: $font-size-sm;
  line-height: $line-height-md;

    @include mq(screen-lg) {
      font-size: $font-size-md;
    }

    strong {
      font-family: $larsseit-bold;
    }

}

.supporting-text--black {
  color: $color-black;
}

.supporting-text--grey {
  color: $color-grey;
}

.supporting-text--white {
  color: white;
}

.excerpt-text {
  font-size: $font-size-xs;
  line-height: $line-height-md;
}

.excerpt-text--grey {
  color: $color-grey;
}

.excerpt-text--white {
  color: white;
}

.img-caption-text {
  font-size: $font-size-xxs;
  opacity: 0.5;
}

.img-caption-text--white {
  color: white;
}