.content-hero {
  position: relative;

  background-color: $color-black;
  
    @media screen and (orientation: portrait) {
      height: 75vh;
    }

    @media screen and (orientation: landscape) {
      height: 100vh;
    }

}

.content-hero__img-outer {
  position: relative;
  z-index: 1;

  height: 100%;

  background-color: $color-black;
}

.content-hero.has-overlay .content-hero__img-outer {

  &:after {
    content: "";

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;

    width: 100%;
    height: 100%;

    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
  }

}

.content-hero__img {
  opacity: $img-fade;
  
  @include object-fit;
}

.content-hero__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;   

  width: 100%;
}

.content-hero__img-caption {
  position: absolute;
  bottom: $padding-sm;
  right: $padding-sm;
  z-index: 4;
}