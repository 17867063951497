.home-hero {
  position: relative;
  width: 100%;
  overflow: hidden;

  background-color: $color-black;

    @media screen and (orientation: portrait) {
      height: 75vh;
    }

    @media screen and (orientation: landscape) {
      height: 100vh;
    }

    @include mq(screen-md) {
      perspective: 200px;
    }

}

.is-safari {
  .home-hero {
    @include mq(screen-md) {
      perspective: 1px;
    }
  }
}

.home-hero__layer-1 {
  position: absolute;
  top: -10%;
  left: -10%;
  z-index: 1;

  width: 120%;
  height: 120%;

  filter: blur(0.5rem);
  opacity: 0.75;

    img {
      display: block;
      object-fit: cover;

      width: 100%;
      height: 100%;
    }

}

.home-hero__layer-2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  transition: transform $animation-speed-lg;
}

.home-hero__layer-2__img,
.home-hero__layer-4__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

    @include mq(screen-md) {
      top: -10%;
      left: -10%;

      width: 120%;
      height: 120%;

      transform: skew(-35deg);
      overflow: hidden;
    }

    img {
      display: block;
      object-fit: cover;

      width: 100%;
      height: 100%;

        @include mq(screen-md) {
          transform: skew(35deg);
        }

    }

}

.home-hero__layer-2__img {

  @include mq(screen-md) {
    box-shadow: 0px 0px 20px rgba(black, 0.25);
  }

}

.home-hero__layer-3 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

    svg {
      width: 66.667%;
      fill: white;

        @include mq(screen-sm) {
          width: 50%;
        }

        @include mq(screen-lg) {
          width: 33.333%;
        }
    }
    
}

.home-hero__layer-4 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;

  width: 100%;
  height: 100%;

  transition: transform $animation-speed-lg;
}

.home-hero__layer-5 {
  position: absolute;
  bottom: $padding-md;
  z-index: 5;

  width: 100%;

    @include mq(screen-lg) {
      bottom: $padding-lg;
    }
    
}

.home-hero__content__title {
  font-size: $font-size-md;
  line-height: $line-height-md;
  color: white;

  text-shadow: 0 2px 3px rgba(black, 0.75);

    @include mq(screen-lg) {
      font-size: $font-size-lg;
    }

    strong {
      font-family: $larsseit-bold;
    }

}

.home-hero__img-caption {
  position: absolute;
  bottom: $padding-sm;
  right: $padding-sm;
  z-index: 6;

  text-shadow: 0 2px 3px rgba(black, 0.75);
}


