// =================================================
// LAYOUT STYLES
// =================================================

.outer {
  position: relative;
}

.outer--bg-white {
  background-color: white;
}

.outer--bg-black {
  background-color: $color-black;
}

.outer--bg-grey-light {
  background-color: $color-grey-light;
}

.outer--bg-grey-light {
  background-color: $color-grey-light;
}

.inner {
  width: 100%;
  margin: 0 auto;
  padding: 0 $padding-md;
}

.inner--sm {

  @include mq(screen-sm) {
    width: $screen-sm;
  }

}

.inner--md {

  @include mq(screen-md) {
    width: $screen-md;
  }

  @include mq(screen-lg) {
    padding: 0 $padding-lg;
  }

}

.inner--lg {

  @include mq(screen-lg) {
    width: $screen-lg;
  }

  @include mq(screen-lg) {
    padding: 0 $padding-lg;
  }

}

.inner--xl {

  @include mq(screen-xl) {
    width: $screen-xl;
  }

  @include mq(screen-lg) {
    padding: 0 $padding-lg;
  }

}

.inner--xxl {
  
  @include mq(screen-xxl) {
    width: $screen-xxl;
  }

}

// Dashboard

.dashboard {
  @include mq(screen-xs) {
    display: flex;
    justify-content: center;
  }
}

.dashboard__col {
  padding: $padding-md 0;
  
    @include mq(screen-xs) {
      width: 50%;
      padding: 0 $padding-md;
    }

}

.dashboard__col + .dashboard__col {
  border-top: 1px solid $color-grey;

    @include mq(screen-xs) {
      border-top: none;
      border-left: 1px solid $color-grey;
    }

}