.services-promo {
  padding: $padding-lg 0;
  overflow: hidden;

    @include mq(screen-sm) {
      display: flex;
      justify-content: space-between;
      min-height: 75vh;
    }

}

.services-promo__imgs {
  display: none;

    @include mq(screen-sm) {
      position: relative;
      display: block;
      width: 60%;
    }

}

.services-promo__img {

    @include mq(screen-sm) {
      position: absolute;
      top: 0;
      left: 0;

      opacity: 1;
      transition: opacity $animation-speed-lg;

      @include object-fit;
    }

    &.is-hidden {
      opacity: 0;
    }

}

.services-promo__info {
  padding: 0 $padding-md;

    @include mq(screen-sm) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
    }

}

.services-promo__text {
  margin: 0 0 $padding-md;

    @include mq(screen-sm) {
      margin: 0 0 25rem;
    }

}

.services-promo__list {
  list-style-type: none;
}

.services-promo__list__item + .services-promo__list__item {
  margin: $padding-md 0 0;

    @include mq(screen-sm) {
      margin: 0;
    }

}

.services-promo__list__link {
  display: flex;
  justify-content: space-between;
  align-items: center;

  text-decoration: none;

    @include mq(screen-sm) {
      padding: $padding-xs 0;
    }

    span {
      font-family: $larsseit-bold;
      font-size: $font-size-sm;
      color: $color-black;

        @include mq(screen-lg) {
          font-size: $font-size-md;

          transition: all $animation-speed-md;
        }

    }

    svg {
      width: 1.5rem;

      fill: $color-black;
      
        @include mq(screen-lg) {
          fill: none;

          transform: translateX(-2.5rem);
          transition: all $animation-speed-md;
        }

    }

    @include mq(screen-lg) {

      &:hover {
      
          span {
            background: $grad-green;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          svg {
            fill: $color-black;

            transform: translateX(0);
          }

      }

    }

}