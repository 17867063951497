.slider-control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;

  background: none;
  border: none;

    svg {
      width: 4rem;

      fill: white;
      transition: fill $animation-speed-sm;
    }

    &:hover {
      
      svg {
        fill: $color-green;
      }

    }

}

.slider-control--previous {

  svg {
    transform: rotate(-180deg);
  }

}