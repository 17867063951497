.services-outer {
  background-color: $color-black;

    @include mq(screen-sm) {
      position: relative;
      height: 75vh;
    }

}

.services-scroller {

  @include mq(screen-sm) {
    position: relative;

    display: flex;
    height: 100%;
  }

}

.services-list-outer {
  padding: $padding-lg $padding-md;

    @include mq(screen-sm) {
      position: relative;
      z-index: 2;

      padding: 0;
      overflow: auto;
      width: 100%;
      height: 100%;
      scroll-snap-type: y proximity;

      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }

    }

}

.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: $padding-md;
  
    @include mq(screen-sm) {
      display: block;
      width: 50%;
      padding: 22.5vh 0;
    }

    @include mq(screen-md) {
      padding: 17.5vh 0;
    }

}

.services-list__item {
  width: 30rem;
  padding: $padding-md;

  background-color: rgba(white, 0.05);

    @include mq(screen-xs) {
      width: calc(50% - 3rem);
    }

    @include mq(screen-sm) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 30vh;
      padding: 0 $padding-md;

      scroll-snap-align: center;

      background: none;
    }

    @include mq(screen-md) {
      height: 40vh;
    }

    @include mq(screen-lg) {
      padding: 0 $padding-lg;
    }

}

.no-flexbox-gap {
  
  .services-list__item {
    margin: $padding-sm;
  }

}

.services-list__item__link {
  display: block;
  text-decoration: none;
  text-align: center;

    @include mq(screen-sm) {
      padding: 0 $padding-md 0 0;

      text-align: left;
    }

    @include mq(screen-lg) {
      width: 66.667%;
      padding: 0;
    }

    @include mq(screen-xl) {
      width: 50%;
    }
  
    h2 {
      margin: 0 0 $padding-xs;
      font-family: $bw-heavy;
      font-size: $title-size-xs;
      line-height: 1;
      text-transform: uppercase;

      background: $grad-green;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

        @include mq(screen-sm) {
          font-size: $title-size-sm;

          filter: grayscale(100%);
          opacity: 0.25;
        }

        @include mq(screen-lg) {
          font-size: $title-size-md;
        }

    }

    p {
      color: white;
      line-height: $line-height-md;
        
        @include mq(screen-sm) {
          opacity: 0.25;
        }
        
    }

    &:hover h2 {
      background: white;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

}

.services-list__item.is-highlighted .services-list__item__link {
  
  h2 {
    
    @include mq(screen-sm) {
      filter: grayscale(0%);
      opacity: 1;
    }

  }

  p {
    
    @include mq(screen-sm) {
      opacity: 1;
    }

  }

}

.services-imgs {
  display: none;
  position: relative;

    @include mq(screen-sm) {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;

      display: block;
      width: 50%;
      height: 100%;
    }

}

.services-img {
  
  @include mq(screen-sm) {
    position: absolute;
    z-index: 1;

    width: 100%;
    height: 100%;

    opacity: 0;
    transition: opacity $animation-speed-lg;
  }

  img {
    position: relative;
    z-index: 1;

    @include object-fit;
  }

  &.is-showing {
    opacity: 1;
  }


}

.services-img__img-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  width: 100%;
  padding: $padding-md $padding-sm $padding-sm;

  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
}