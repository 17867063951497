.responsive-table {
  position: relative;
  width: 100%;
}

.responsive-table table {
  border-collapse: collapse;
  border-spacing: 0px;

  background-color: $color-grey-light;

  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;

    tr:not(:last-child) td {
      border-bottom: 1px solid white;
    }

    th {
      border-bottom: 3px solid $color-black;

      font-family: $larsseit-bold;
      color: $color-black;
    }

    th,td {
      padding: 1rem;

      font-size: $font-size-xs;
      line-height: $line-height-md;
      vertical-align: top;
      text-align: left;
    }


}

.responsive-table__helper {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0 0 1rem;

  font-family: $larsseit-bold;
  font-size: $font-size-xxs;

  background-color: $color-green;
}